import API from '../api';

export const SIGNIN = 'SIGNIN';
export const SIGNIN_OAUTH = 'SIGNIN_OAUTH';
export const WRONG_PASSWORD = 'INVALID_USER';
export const INVALID_USER = 'INVALID_USER';
export const NO_EMAIL = 'NO_EMAIL';
export const RD_SIGN_UP_4001 = 'RD-4001';
export const RD_SIGN_UP_4002 = 'RD-4002';

export const FAILED_TO_CREATE_A_KEY = 'FAILED_TO_CREATE_A_KEY';
export const TIMEOUT = 'TIMEOUT';

export const SUCCESS = 'SUCCESS'; // 200

export const INTERNAL_SERVER = 'INTERNAL_SERVER'; // 500
export const SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE'; //503
export const BAD_REQUEST = 'BAD_REQUEST'; // 400
export const NOT_FOUND = 'NOT_FOUND'; // 404
export const FORBIDDEN = 'FORBIDDEN'; // 403

export const MAX_RETRY = 'MAX_RETRY';
export const PERMISSION = 'PERMISSION';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const UPLOAD_CREATE_FOLDER = 'UPLOAD_CREATE_FOLDER';
export const USER_ABORTED = 'USER_ABORTED';
export const UNKNOWN = 'UNKNOWN';
export const ALREADY_EXISTS = 'ALREADY_EXISTS';
export const SERVER = 'SERVER';
export const NETWORK = 'NETWORK';
export const EXPIRED = 'EXPIRED';
export const INVALID = 'INVALID';
export const INVALID_PARAM = 'INVALID_PARAM';
export const CREATE_FOLDER_NOT_FOUND = 'CREATE_FOLDER_NOT_FOUND';

export const COMMENT_NOT_FOUND = 'COMMENT_NOT_FOUND';
export const INVALID_SHARE_LINK = 'INVALID_SHARE_LINK';
export const NO_PERMISSION_SHARE_LINK = 'NO_PERMISSION_SHARE_LINK';
export const SHARE_LINK_NOT_FOUND = 'SHARE_LINK_NOT_FOUND';
export const SHARE_FOLDER_INCLUDED_OR_INCLUDE = 'SHARE_FOLDER_INCLUDED_OR_INCLUDE';
export const MOVE_SHARE_FOLDER = 'MOVE_SHARE_FOLDER';

// file operation
export const WRONG_PATH = 'WRONG_PATH';
export const STORAGE_LIMIT_EXCEEDED = 'STORAGE_LIMIT_EXCEEDED';
export const TOO_LONG_NAME = 'TOO_LONG_NAME';

export const translateErrors = (operation, error, t) => {
    const code = API.extractCode(error);
    let translateId = code;
    if (!translateId) {
        const response = API.extractResponse(error);
        if (response && response.status) {
            switch (response.status) {
                case 400:
                    translateId = BAD_REQUEST;
                    break;
                case 401:
                    translateId = UNAUTHORIZED;
                    break;
                case 403:
                    translateId = FORBIDDEN;
                    break;
                case 404:
                    translateId = NOT_FOUND;
                    break;
                case 500:
                    translateId = INTERNAL_SERVER;
                    break;
                case 503:
                    translateId = SERVICE_UNAVAILABLE;
                    break;
                default:
                    translateId = UNKNOWN;
                    break;
            }
        }
    }
    const operationCode = `${translateId}_${operation.id.toUpperCase()}`;
    const operationCodeTranslated = t(operationCode);
    if (operationCodeTranslated !== operationCode) {
        return operationCodeTranslated;
    }
    return t(translateId);
};
