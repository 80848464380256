export default class Task {
    store;
    events;

    userInfo() {
        return this.store.getState().account.userInfo;
    }

    idToken() {
        return this.userInfo().idToken;
    }

    uid() {
        return this.userInfo().uid;
    }

    email() {
        return this.userInfo().email;
    }

    constructor(store, events) {
        this.store = store;
        this.events = events;
    }

    process = async _ => {};
}
