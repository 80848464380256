import { HOME } from '../constants/routes';

export const beautifyFileSize = n => {
    if (!n) n = 0;

    if (n < 1024) {
        return n + ' B';
    } else if (n < 1024 * 1024) {
        return Math.round((n / 1024) * 100) / 100.0 + ' KB';
    } else if (n < 1024 * 1024 * 1024) {
        return Math.round((n / 1024 / 1024) * 100) / 100.0 + ' MB';
    } else if (n < 1024 * 1024 * 1024 * 1024) {
        return Math.round((n / 1024 / 1024 / 1024) * 100) / 100.0 + ' GB';
    } else {
        return Math.round((n / 1024 / 1024 / 1024 / 1024) * 100) / 100.0 + ' TB';
    }
};

export const getTotalSize = files => {
    if (!files) return;

    let totalSize = 0;

    for (var i = 0; i < files.length; i++) {
        const file = files[i];
        if (file && file.size) totalSize += file.size;
    }

    return totalSize;
};

export const reduceFileName = (filename, length = 8) => {
    const endpoint = filename.lastIndexOf('.');
    const name = filename.substring(0, endpoint);

    if (name.length <= length) return filename;

    const format = endpoint !== -1 ? filename.substring(endpoint + 1) : '';

    return `${name.substring(0, length)}...${format}`;
};

export const getFileFormat = (filename = '') => {
    if (!filename) return filename;

    return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();
};

const thumbnailExts = {
    zip: 'zip',
    rar: 'zip',
    alz: 'zip',
    tar: 'zip',
    doc: 'word',
    docx: 'word',
    xls: 'xls',
    xlsx: 'xls',
    pdf: 'pdf',
    ppt: 'ppt',
    key: 'ppt',
    pptx: 'ppt',
    txt: 'text',
    hwp: 'text',
    mp3: 'mp3',
    wav: 'sound',
    aiff: 'sound',
    aac: 'sound',
    mp4: 'video',
    avi: 'video',
    flv: 'video',
    mkv: 'video',
    m4v: 'video',
    mpg: 'video',
    mov: 'video',
    png: 'png',
    psd: 'psd',
    jpg: 'image',
    gif: 'image',
    tiff: 'image',
    jpeg: 'image',
    css: 'css',
    svg: 'vector',
    dxf: 'vector',
    eps: 'vector',
    aaf: 'ae',
    aep: 'ae',
    aet: 'ae',
    aepx: 'ae',
    ai: 'ai',
    ait: 'ai',
    indd: 'id',
    prproj: 'pr',
    smi: 'smi',
    srt: 'smi',
    dll: 'dll',
    htm: 'html',
    html: 'html',
    js: 'html',
    sketch: 'skt',
    '3ds': '3d',
    '3mf': '3d',
    blend: '3d',
    dae: '3d',
    fbx: '3d',
    tif: '3d',
    xd: 'xd',
};

export const getFileFormatImage = format => {
    return `${HOME}/static/assets/og/${thumbnailExts[format] || 'default'}.png`;
};

export const stringNormalize = s => {
    return s && !!String.prototype.normalize ? s.normalize() : s;
};

export const getFileNameFromPath = p => {
    try {
        const arry = p.split('/');
        if (arry && arry.length) {
            return arry[arry.length - 1];
        }
    } catch (e) {}

    return p;
};
