import { createTheme } from '@material-ui/core/styles';
import Styles from './styles';
import * as Colors from '@bit/estmob.frontier-components.colors';

const DEFAULT_BACKGROUND = Colors.GRAY0;

const GlobalTheme = createTheme({
    palette: {
        primary: {
            main: Colors.TEAL6,
        },
        secondary: {
            main: Colors.TEAL0,
        },
        text: {
            primary: Colors.GRAY8,
            secondary: Colors.GRAY6,
        },
        action: {
            selected: Colors.TEAL0,
        },
        divider: Colors.GRAY3,
    },
    typography: {
        useNextVariants: true,
        h1: {
            fontWeight: Styles.TextWeight.Bold,
            fontSize: '80px',
            letterSpacing: '-2px',
            lineHeight: '80px(1)',
        },
        h2: {
            fontWeight: Styles.TextWeight.Bold,
            fontSize: '36px',
            letterSpacing: '-1px',
            lineHeight: '48px(1.3)',
        },
        h3: {
            fontWeight: Styles.TextWeight.Bold,
            fontSize: '24px',
            letterSpacing: '-0.4px',
            lineHeight: '32px(1.3)',
        },
        h4: {
            fontWeight: '500',
            fontSize: '20px',
            letterSpacing: '-0.5px',
            lineHeight: '28px(1.4)',
        },
        subtitle1: {
            fontWeight: 'regular',
            fontSize: '16px',
            letterSpacing: '-0.5px',
            lineHeight: '24px(1.5)',
        },
        body1: {
            fontWeight: 'regular',
            fontSize: '14px',
            letterSpacing: '-0.4px',
            lineHeight: '20px(1.4)',
        },
        body2: {
            fontWeight: 'regular',
            fontSize: '12px',
            letterSpacing: '0px',
            lineHeight: '16px(1.3)',
        },
    },
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: 0,
                marginRight: '8px',
            },
        },
        MuiListItemText: {
            multiline: {
                marginTop: 0,
                marginBottom: 0,
            },
            primary: {
                lineHeight: '20px',
                fontSize: '14px',
                color: Colors.GRAY6,
            },
            secondary: {
                lineHeight: '20px',
                fontSize: '12px',
                color: Colors.GRAY5,
            },
        },
        MuiListItem: {
            gutters: {
                paddingLeft: '12px',
                paddingRight: '12px',
            },
            button: {
                '&:hover': {
                    backgroundColor: DEFAULT_BACKGROUND,
                },
            },
        },
        MuiListItemAvatar: {
            root: {
                marginRight: '8px',
                minWidth: 0,
            },
        },
        MuiMenuItem: {
            root: {
                minHeight: '40px',
            },
        },
        MuiIconButton: {
            root: {
                padding: 0,
            },
        },
        MuiGridListTile: {
            tile: {
                overflow: 'visible',
            },
        },
        MuiToolbar: {
            root: {
                backgroundColor: '#fff',
                color: Colors.GRAY7,
            },
            regular: {
                padding: '0 16px',
            },
        },
        MuiLink: {
            root: {
                textTransform: 'none',
            },
        },
        MuiTableRow: {
            root: {
                '&$selected': {
                    backgroundColor: Colors.TEAL0,
                },
                '&$hover:hover': {
                    backgroundColor: DEFAULT_BACKGROUND,
                },
            },
        },
        MuiButton: {
            root: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
            },
            containedPrimary: {
                color: '#fff',
            },
            outlined: {
                color: Colors.GRAY6,
                borderColor: 'rgba(0, 20, 61, 0.08)',
            },
            text: {
                padding: 0,
                minWidth: 0,
            },
        },
        MuiTableSortLabel: {
            root: {
                '&$active': {
                    color: Colors.GRAY6,
                },
            },
        },
        MuiInputBase: {
            input: {
                color: Colors.GRAY6,
            },
        },
        MuiDialog: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
            paper: {
                backgroundColor: DEFAULT_BACKGROUND,
            },
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: 'white',
                padding: '32px',

                '& *': {
                    ...Styles.TextStyles.h4,
                    fontWeight: '500',
                    color: Colors.GRAY7,
                },
            },
        },
        MuiDialogContent: {
            root: {
                padding: '32px',
                backgroundColor: DEFAULT_BACKGROUND,
            },
        },
        MuiDialogActions: {
            root: {
                padding: '0 32px 32px 32px',
                margin: 0,
            },
        },
        MuiAvatar: {
            colorDefault: {
                backgroundColor: Colors.TEAL0,
                color: Colors.TEAL5,
            },
        },
        MuiLinearProgress: {
            bar1Determinate: {
                transition: 'transform .3s linear',
            },
        },
        MuiTypography: {
            root: {
                whiteSpace: 'pre-line',
            },
        },
        MuiTooltip: {
            tooltipPlacementBottom: {
                margin: 0,
                '@media (min-width: 600px)': {
                    margin: '0',
                },
            },
        },
        // MuiPopover: {
        //     paper: {
        //         padding: '10px 10px 10px 10px',
        //         backgroundColor: 'red',
        //     }
        // }
    },
});

export default GlobalTheme;
