import Path from 'path';
import Common from '../common';
import { ServiceInfo } from '../../constants';

const SERVICE = 'user';
const VERSION = 1;

const implement = block => Common.implement(ServiceInfo.cloudApiUrl, SERVICE, VERSION, block);

const getUserInfo = hostId => implement(instance => instance.get(Path.join('users', hostId, 'info')));

const setUserSettings = (hostId, trash_empty_cycle = null) => {
    if (trash_empty_cycle) {
        return implement(instance =>
            instance.post(Path.join('users', hostId, 'settings'), {
                trash_empty_cycle,
            }),
        );
    } else {
        return new Promise((resolve, reject) => Common.managedResolve(resolve, null));
    }
};

export default {
    getUserInfo,
    setUserSettings,
};
