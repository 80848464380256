import './bootstrap';

import React, { Suspense } from 'react';
import configureStore, { browserHistory } from './redux/store';
import { deepAssign, loadState } from './utils';

import App from './App';
import { ConnectedRouter } from 'connected-react-router';
import ErrorBoundary from './ErrorBoundary';
import { Firebase } from './helper';
import GlobalTheme from './theme';
import { MuiThemeProvider } from '@material-ui/core';
import { Provider as ReduxProvider } from 'react-redux';
import ga from './ga';
import initialState from './redux/initialState';
import { parseUrl } from 'query-string';

ga.init('G-93MFVTT03W');

// ReactGA.set({ userId: 123 });

window.debug.log('initialState: ', initialState);
const storageState = loadState();
window.debug.log('loadedState: ', storageState);
const state = deepAssign({}, initialState, storageState);
// Test for token expiring while browser is offline.
// if (state.account.userInfo.idToken) {
//     state.account.userInfo.idToken = state.account.userInfo.idToken + '1'
// }
//state.account.userInfo.isLogin = false
window.debug.log('combinedState: ', state);
export const store = configureStore(state);
window.store = store;
// const history = browserHistory
// const unlisten = history.listen(({pathname, search, hash, state, key}, action) => {
//     window.debug.log(action, pathname, search, hash, state, key);
//     if (pathname !== '/') Ga.MANAGER.setPageView(pathname)
// });

// Ga.MANAGER.setUserId(store.getState().account.userInfo.uid);
// if (history && history.location) Ga.MANAGER.setPageView(history.location.pathname)

window.query = parseUrl(window.location.href).query || {};

console.log('deployed')

class Root extends React.Component {
    firebase = new Firebase();
    componentWillMount() {
        const query = window.query;
        if (query) {
            var setting = store.getState().setting ? store.getState().setting : {};
            if (query.gmail !== undefined) {
                setting.activateGmail = query.gmail === 'true';
            }
            if (query.slack !== undefined) {
                setting.activateSlack = query.slack === 'true';
            }
            if (query.chatwork !== undefined) {
                setting.activateChatwork = query.chatwork === 'true';
            }
            store.getState().setting = setting;
        }

        this.firebase.SocialApisLoads().then(() => {
            window.debug.log('complete social apis loads');
        }).catch(error => {
            window.debug.log(error);
        })
    }

    componentWillUnmount() {
        // unlisten()
        window.debug = null;
        window.release = null;
    }

    render() {
        return (
            <Suspense fallback={<div />}>
                <ErrorBoundary>
                    <ReduxProvider store={store}>
                        <ConnectedRouter history={browserHistory}>
                            <MuiThemeProvider theme={GlobalTheme}>
                                <App />
                            </MuiThemeProvider>
                        </ConnectedRouter>
                    </ReduxProvider>
                </ErrorBoundary>
            </Suspense>
        );
    }
}

export default Root;
