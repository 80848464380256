// for debug
export const UPDATE_FILE_LIST = 'UPDATE_FILE_LIST';

export const updateFileList = (dispatch, files, merge) => {
    dispatch({
        type: UPDATE_FILE_LIST,
        files,
        merge,
    });
};
