import API, { RelayServer } from '../../api';
import { CLOUD_STATE, Errors, SENT_DETAIL, ServiceInfo, TRANSFER_STATE } from '../../constants';
import { ifYes, loopTimeout, safe, stringNormalize } from '../../utils';

import AWS from 'aws-sdk';
import { FILE_FROM, TRANSFER_OBJECT_TYPE } from '../../constants/transfer';
import Path from 'path';
import Task from './Task';
import { AnonymousCredential, BlobServiceClient, newPipeline } from '@azure/storage-blob';
import { AbortController } from '@azure/abort-controller'

const apiVersion = '2019-06-10';

const pipeline = newPipeline(new AnonymousCredential(), {
    retryOptions: { maxTries: 4 }, // Retry options
    keepAliveOptions: {
      enable: false
    }
});

const controller = new AbortController();

export default class SendTask extends Task {
    store;
    keyData;
    server;
    transfer_id;
    http;
    path;
    bucket;
    s3region;
    prefix;
    params;
    files;
    currentCount = 0;
    canceled = false;
    token;
    transferObject;

    constructor(
        store,
        sourceType,
        files,
        {
            download_limit,
            expire_time,
            sent_subject,
            sent_content,
            sent_emails,
            sent_slacks,
            sent_chatworks,
            password,
            use_comment,
            save_to_sendy,
            skip_thumbnail = 1,
        },
        events,
        token,
    ) {
        super(store, events, _ => ({
            baseURL: this.server,
        }));

        this.sourceType = sourceType;
        this.files = files;

        this.params = {
            mode: 'upload',
            download_limit,
            expire_time,
            sent_subject,
            sent_content,
            sent_emails,
            sent_slacks,
            sent_chatworks,
            password,
            use_comment,
            save_to_sendy,
            skip_thumbnail,
        };
        this.token = token;
    }

    getS3Param = (credentials, bucket, region) => ({
        apiVersion,
        sslEnabled: true,
        region: region,
        params: {
            Bucket: bucket,
        },
        ...credentials,
        // httpOptions: {
        //     timeout: 0,
        // }
    });

    _onStart = async files => {
        const dataFiles = files.map(f => {
            return {
                path: f.name,
                size: f.size,
                prefix: f.prefix,
            }
        });

        this.relay = new RelayServer(this.server, this.store);
        this.canceled = false;
        this.currentCount = 0;

        const host_id = this.uid();
        const transfer_id = this.transfer_id;
        const keyData = this.keyData;

        let result = API.managedError();
        if (transfer_id && host_id) {
            const data = API.extractData(await this.relay.start(keyData.key, transfer_id, dataFiles));
            if (data) {
                const { bucket, s3region, prefix } = data;
                this.bucket = bucket;
                this.s3region = s3region;
                this.prefix = prefix;
                return API.managedResolve(null, data);
            } else {
                throw API.managedReject(Errors.FAILED_TO_CREATE_A_KEY);
            }
        }
        return result;
    };

    _onCheck = async () => {
        return new Promise((resolve, reject) => {
            const task = setInterval(async () => {
                try {
                    if (this.canceled) {
                        clearInterval(task);
                        resolve('');
                    }
                    const res = API.extractData(await this.relay.check(this.keyData.key, this.transfer_id));
                    if (res) {
                        const { state } = res;
                        switch (state) {
                            case 'complete':
                                clearInterval(task);
                                resolve('');
                                break;
                            case 'error':
                                clearInterval(task);
                                reject('response_state_is_not_transfer');
                                break;
                            default:
                                break;
                        }
                    }
                } catch (error) {
                    clearInterval(task);
                    reject(error);
                }
            }, 500);
        });  
    };

    _onFinish = async result => {
        const transfer_id = this.transfer_id;
        const keyData = this.keyData;

        try {
            if (transfer_id && keyData) {
                return await this.relay.finish(
                    keyData.key,
                    transfer_id,
                    (this.canceled && TRANSFER_STATE.CANCEL) || (result.error ? TRANSFER_STATE.FAIL : TRANSFER_STATE.COMPLETE),
                    ifYes(API.isSucceeded(result) && this.params.save_to_sendy, _ => {
                        const summary = this.files[0].name;
                        const email = this.store.getState().account.userInfo.email;
                        return {
                            to_path: API.s3.getCopyPath(summary.substring(0, summary.lastIndexOf('.'))) + '/',
                            notify_target: email,
                            detail_url: ServiceInfo.transferUrl + SENT_DETAIL.replace(':created_at', keyData.created_at),
                        };
                    }),
                );
            } else {
                return API.managedError(Errors.FAILED_TO_CREATE_A_KEY);
            }
        } finally {
            this.relay = null;
        }
    };

    _onFail = async _ => {};

    _onCancel = async _ => {
        setTimeout(_ => {
            window.transferManager.deleteKey(this.keyData);
        });
    };

    _onComplete = async result => {};

    _uploadFiles = async (fileList, filesPrefix) => {
        const progressCallback = safe(this.events, 'onProgress');
        try {
            const files = fileList.slice(0);
            const credit = await this.relay.createCredit(this.bucket);

            if (this.relay.transferType === TRANSFER_OBJECT_TYPE.s3Object) {
                this.transferObject = new AWS.S3(this.getS3Param(credit, this.bucket, this.region));
            } else {
                const blobServiceClient = new BlobServiceClient(
                    `${credit.accountStorageUrl}${credit.sas}`,
                    pipeline
                );
                this.transferObject = blobServiceClient.getContainerClient(credit.containerName);
            }

            const totalSize = files.reduce((value, item) => value + item.size, 0);
            const totalCount = files.length;
            let count = 0;
            let currentSize = 0;

            const progressHandler = this.relay.transferType === TRANSFER_OBJECT_TYPE.s3Object ? obj => evt => {
                if (progressCallback) {
                    this.currentCount = count;
                    this.canceled = this.canceled || progressCallback(evt.key, totalSize, currentSize + evt.loaded, totalCount, count);
                    if (this.canceled) {
                        obj.abort();
                    }
                }
            } : ((ev) => {
                if (progressCallback) {
                    this.currentCount = count;
                    this.canceled = this.canceled || progressCallback(null, totalSize, currentSize + ev.loadedBytes, totalCount, count);
                    //TODO: canceled 일 때 처리
                    if (this.canceled) {
                        controller.abort();
                    }
                }
            });
            
            for (let i = 0; i < files.length; i++) {
                const item = files[i];
                const fullKey = this.prefix + filesPrefix[i].path;
                const params = {
                    Key: fullKey,
                    ContentType: item.type,
                    Body: item,
                };

                if (this.relay.transferType === TRANSFER_OBJECT_TYPE.s3Object) {
                    const s3obj = this.transferObject.upload(params);
                    await s3obj.on('httpUploadProgress', progressHandler(s3obj)).promise();
                } else {
                    const blobClient = this.transferObject.getBlobClient(fullKey);
                    const blockBlobClient = blobClient.getBlockBlobClient();
                    const abortSignal = controller.signal;
                    await blockBlobClient.uploadData(item, {
                        abortSignal: abortSignal,
                        onProgress: (ev) => progressHandler(ev)
                    });
                }
                ++count;
                currentSize += item.size;
                
                if (progressCallback) {
                    progressCallback(null, totalSize, currentSize, totalCount, count);
                }
            }

            await this._onCheck();

            if (progressCallback) {
                this.canceled = this.canceled || progressCallback(null, totalSize, totalSize, totalCount, totalCount);
            }

            return API.managedOk();
        } catch (error) {
            window.debug.error(error);
            const response = API.extractResponse(error);
            if (response) {
                const message = response.data ? response.data.error : null;
                if (this.relay.transferType === TRANSFER_OBJECT_TYPE.s3Object) {
                    switch (message) {
                        case 'SEDNY_ERR_EXCEEDED_FOLDER_MAX_STORAGE':
                            return API.managedReject(null, error.error, { code: Errors.STORAGE_LIMIT_EXCEEDED });
                        case 'SENDY_ERR_FILE_NO_SUCH_KEY':
                            return API.managedReject(null, error, { code: Errors.NOT_FOUND });
                        default:
                            return API.managedReject(null, error, {
                                ...(this.canceled ? { code: Errors.USER_ABORTED } : null),
                            });
                    }
                }
            } else {
                return API.managedReject(null, error, {
                    ...(this.canceled ? { code: Errors.USER_ABORTED } : null),
                });
            }
        }
    };

    _createKey = async ({
        mode,
        file,
        download_limit,
        expire_time,
        sent_subject,
        sent_content,
        sent_emails,
        sent_slacks,
        sent_chatworks,
        password,
        use_comment,
        skip_thumbnail,
        token,
    }) => {
        return API.transfer.key.create({
            file,
            mode,
            download_limit,
            expire_time,
            sent_subject,
            sent_content,
            sent_emails,
            sent_slacks,
            sent_chatworks,
            password,
            use_comment,
            skip_thumbnail,
            token,
        });
    };

    _copyCheck = async (task_id, callback, serverUrl) =>
        await loopTimeout(500, async _ => {
            const result = await API.transfer.relay.check(task_id, serverUrl);
            const data = API.extractData(result);

            switch (data.state) {
                case CLOUD_STATE.COMPLETE:
                    return result;
                case CLOUD_STATE.CANCEL:
                    throw API.managedError(Errors.USER_ABORTED, data);
                case CLOUD_STATE.FAIL:
                    throw API.managedError(Errors.UNKNOWN, data);
                default:
                    break;
            }

            if (callback) {
                this.canceled = this.canceled || callback(data.total, data.sent);
            }
            return this.canceled ? API.managedError(Errors.USER_ABORTED, data) : undefined;
        });

    _copyFromCloud = async (filesInfo, serverUrl) => {
        const uid = this.uid();
        const fileObj = filesInfo;
        
        let result = await API.transfer.relay.uploadFromCloud({ file: fileObj, host_id: uid, transfer_key: this.keyData.key, relay_server_url: this.keyData.server }, serverUrl);
        window.debug.log(result);
        const data = API.extractData(result);
        if (data && API.isSucceeded(result)) {
            const progressCallback = safe(this.events, 'onProgress');
            const TIMEOUT = 10000;
            let timeout = +new Date() + TIMEOUT;
            const callback = (total, sent) => {
                if (+new Date() > timeout) {
                    throw API.managedError(Errors.TIMEOUT);
                }
                timeout = +new Date() + TIMEOUT;
                this.currentCount = sent;
                const canceled = progressCallback && progressCallback(undefined, total, sent, total, sent);
                return canceled || false;
            };
            result = await this._copyCheck(data.task_id, callback, serverUrl);
            window.debug.log(result);
        }
        return result;
    };

    _uploadFromLocal = async (filesPrefix) => {
        let result = API.managedError();
        if (this.keyData) {
            const uid = this.uid();
            if (uid && this.bucket) {
                result = await this._uploadFiles(this.files, filesPrefix);
            }
        }
        return result;
    };

    process = async _ => {
        let result = API.managedError();
        try {
            const filesInfo = this.files.map(item => ({ name: stringNormalize(item.fullPath || item.path), size: item.size }));
            result = await this._createKey({ file: filesInfo, ...this.params, token: this.token });
            if (API.isSucceeded(result)) {
                const data = API.extractData(result);
                if (data) {
                    window.debug.table(data);

                    const { server, transfer_id } = data;
                    this.keyData = data;
                    this.server = server;
                    this.transfer_id = transfer_id;
                    const {data: startResult} = await this._onStart(filesInfo);
                    const cloudServerUrl = startResult.hasOwnProperty('transfer_svc_url') ? 'https://' + startResult.transfer_svc_url + '/cloud/service' : undefined;
                    switch (this.sourceType) {
                        case FILE_FROM.LOCAL:
                            result = await this._uploadFromLocal(startResult.files);
                            break;
                        default:
                            result = await this._copyFromCloud(filesInfo, cloudServerUrl);
                            break;
                    }
                }
            }
        } catch (error) {
            window.debug.error(error);
            throw error;
        } finally {
            if (this.canceled) {
                //await this.relay.putState(this.keyData.key, this.transfer_id, TRANSFER_STATE.CANCEL, this.currentCount);
                this._onCancel();
            } else if (!API.isSucceeded(result)) {
                //this.relay && await this.relay.putState(this.keyData.key, this.transfer_id, TRANSFER_STATE.FAIL, this.currentCount);
                this._onFail();
            } else {
                this._onComplete(result);
            }
            await this._onFinish(result);
        }
        return result;
    };
}
