import { IS_DEV } from '../environment';

export const SupportLanguages = {
    english: {
        keywords: ['english', 'en', 'en-us', 'us'],
        value: 'english',
        translateId: 'language_en',
        i18nLanguage: 'en-US',
        langCode: 'en',
    },
    japanese: {
        keywords: ['japanese', 'ja', 'ja-jp', 'jp'],
        value: 'japanese',
        translateId: 'language_ja',
        i18nLanguage: 'ja-JP',
        langCode: 'ja',
    },

    has: function(value) {
        if (!value) {
            return false;
        }
        const combined = Object.entries(this)
            .map(item => item[1].keywords)
            .filter(item => item)
            .reduce((v, item) => {
                return v.concat(item);
            }, []);
        return combined.includes(value.toLowerCase());
    },

    match: function(value) {
        const lowerValue = (value || 'english').toLowerCase();

        for (const item of Object.entries(this)) {
            const val = item[1];

            if (val.keywords && val.keywords.includes(lowerValue)) {
                return val;
            }
        }
        return this.english;
    },
};

if (IS_DEV) {
    SupportLanguages.korean = {
        keywords: ['korean', 'ko', 'ko-kr', 'kr'],
        value: 'korean',
        translateId: 'language_ko',
        i18nLanguage: 'ko-KR',
        langCode: 'ko',
    };
}
