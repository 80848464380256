import Common from '../common';
import { ServiceInfo } from '../../constants';

const SERVICE = 'transfer';
const VERSION = 1;

const implement = (block, version = VERSION) => Common.implement(ServiceInfo.otherApiUrl, SERVICE, version, block);

const create = async params => implement(instance => instance.post('/key/create', params), 2);

const remove = async key => implement(instance => instance.post(`/key/delete/${key}`));

const search = async (key, params) => implement(instance => instance.post(`/key/search/${key}`, params));

const query = async (key, params) => implement(instance => instance.post(`/key/relay/query/${key}`, params));

const setUseComment = (key, use_comment) => implement(instance => instance.post(`/key/comment/${key}`, { use_comment }));

const setPassword = (key, password) => implement(instance => instance.post(`/key/password/${key}`, { password }));

const sendEmail = (key, params) => implement(instance => instance.post(`/key/email/${key}`, params));

const list = (limit, last_key) => implement(instance => instance.get('/links', { params: { limit, last_key } }));

const removeHistory = async created_at => implement(instance => instance.delete(`/links/${created_at}`));

const isExpired = created_at => created_at - +new Date() / 1000 < 0;

export default {
    key: {
        create,
        remove,
        search,
        query,
        setUseComment,
        setPassword,
        sendEmail,
    },
    link: {
        list,
        remove: removeHistory,
        isExpired,
    },
};
