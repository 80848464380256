import Common from '../common';
import { ServiceInfo } from '../../constants';

const SERVICE = 'file';
const VERSION = 1;

const implement = block => Common.implement(ServiceInfo.cloudApiUrl, SERVICE, VERSION, block);

const getRecent = (hostId, param = {}) =>
    implement(instance =>
        instance.post('recent', {
            host_id: hostId || Common.getId(),
            ...param,
        }),
    );

export default {
    getRecent,
};
