import Path from 'path';
import Common from '../common';
import { Errors, ServiceInfo } from '../../constants';

const SERVICE = 'sharing';
const VERSION = 1;

const implement = block => Common.implement(ServiceInfo.cloudApiUrl, SERVICE, VERSION, block);

const getAccessableFolders = param =>
    implement(instance =>
        instance.post('folders/accessable', {
            ...param,
        }),
    );

const acceptShareLink = (link, password = null) => {
    const targetPath = `links/${link}/accept`;
    const userInfo = Common.getUserInfo();
    return implement(instance =>
        instance
            .post(targetPath, {
                link: link,
                user_id: userInfo.uid,
                email: userInfo.email,
                ...(password && { password }),
            })
            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        throw Common.managedReject(null, error, { code: Errors.INVALID });
                    case 401:
                        throw error;
                    case 403:
                        if (error.response.data && error.response.data.error) {
                            switch (error.response.data.error) {
                                case 'SENDY_ERR_PASSWORD_NOT_MATCH':
                                    throw Common.managedReject(null, error, { code: Errors.INVALID_PARAM });
                                case 'SENDY_ERR_LINK_EXPIRED':
                                    throw Common.managedReject(null, error, { code: Errors.EXPIRED });
                                case 'SENDY_ERR_UNINVITED_USER':
                                    throw Common.managedReject(null, error, { code: Errors.FORBIDDEN });
                                default:
                                    break;
                            }
                        }
                        break;
                    default:
                        throw Common.managedReject(null, error, { code: Errors.NOT_FOUND });
                }
            }),
    );
};

const checkFolderShareable = path => {
    return implement(instance =>
        instance
            .post('folders/shareable', {
                host_id: Common.getId(),
                path: Common.preprocessFolderPath(path),
            })
            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        throw Common.managedReject(null, error, { code: Errors.INVALID });
                    case 401:
                        throw error;
                    case 403:
                        if (error.response.data && error.response.data.error) {
                            switch (error.response.data.error) {
                                case 'SENDY_ERR_SHARE_FOLDER_INCLUDED_OR_INCLUDE':
                                    throw Common.managedReject(null, error, { code: Errors.SHARE_FOLDER_INCLUDED_OR_INCLUDE });
                                default:
                                    break;
                            }
                        }
                        break;
                    default:
                        throw Common.managedReject(null, error, { code: Errors.NOT_FOUND });
                }
            }),
    );
};

const createInviteFolder = (path, level, password, expiration) =>
    implement(instance =>
        instance.post('folders/invite', {
            host_id: Common.getId(),
            path: Common.preprocessFolderPath(path),
            access_level: level,
            ...(password && { password }),
            ...(expiration && { expiration }),
        }),
    );

const createShareFolder = (path, message, users) =>
    implement(instance =>
        instance.post('folders/share', {
            host_id: Common.getId(),
            path: Common.preprocessFolderPath(path),
            message: message,
            collaborator_list: users,
        }),
    );

const getCollaborators = path => {
    return implement(instance =>
        instance
            .post('folder/collaborators', {
                host_id: Common.getId(),
                path: Common.preprocessFolderPath(path),
            })
            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        throw Common.managedReject(null, error, { code: Errors.INVALID });
                    case 401:
                        throw error;
                    default:
                        throw Common.managedReject(null, error, { code: Errors.NOT_FOUND });
                }
            }),
    );
};

const getInviteLink = path => {
    return implement(instance =>
        instance
            .post('folder/link/invite', {
                host_id: Common.getId(),
                path: Common.preprocessFolderPath(path),
            })
            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        throw Common.managedReject(null, error, { code: Errors.INVALID });
                    case 401:
                        throw error;
                    default:
                        throw Common.managedReject(null, error, { code: Errors.NOT_FOUND });
                }
            }),
    );
};

const getLinkInfo = link =>
    implement(instance =>
        instance.post(Path.join('links', link), {}).catch(error => {
            switch (error.response.status) {
                case 401:
                    throw error;
                case 403:
                    throw Common.managedReject(null, error, { code: Errors.EXPIRED });
                default:
                    throw Common.managedReject(null, error, { code: Errors.NOT_FOUND });
            }
        }),
    );

const unshareLink = (path, type) =>
    implement(instance =>
        instance
            .post('folder/link/unshare', {
                host_id: Common.getId(),
                path: Common.preprocessFolderPath(path),
                link_type: type,
            })
            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        throw Common.managedReject(null, error, { code: Errors.INVALID_SHARE_LINK });
                    case 401:
                        throw error;
                    case 403:
                        throw Common.managedReject(null, error, { code: Errors.NO_PERMISSION_SHARE_LINK });
                    default:
                        throw Common.managedReject(null, error, { code: Errors.SHARE_LINK_NOT_FOUND });
                }
            }),
    );

export default {
    getAccessableFolders,
    acceptShareLink,
    checkFolderShareable,
    createInviteFolder,
    createShareFolder,
    getCollaborators,
    getInviteLink,
    getLinkInfo,
    unshareLink,
};
