export const SHARE_PLATFORMS = {
    EMAIL: 'emails',
    SLACK: 'slacks',
    CHATWORK: 'chatworks',
};

export const TRANSFER_MODE = {
    DIRECT: 'direct',
    UPLOAD: 'upload',
};

export const TRANSFER_STATE = {
    INIT: 'init',
    WAIT: 'wait',
    TRANSFER: 'transfer',
    COMPLETE: 'complete',
    CANCEL: 'cancel',
    OTHER_PARTY_CANCEL: 'cancelby',
    FAIL: 'fail',
};

export const CLOUD_STATE = {
    WAIT: 'waiting',
    RUN: 'running',
    FAIL: 'error',
    COMPLETE: 'complete',
    CANCEL: 'cancel',
};

export const FILE_FROM = {
    LOCAL: 'local',
    CLOUD: 'cloud',
};

export const TRANSFER_OBJECT_TYPE = {
    s3Object: 's3Object',
    azureContainer: 'azureContainer'
}