import ReactGA from 'react-ga4';
import Category from './category';
import Action from './action';
import Label from './label';
import Page from './page';

const init = (trackCode) => ReactGA.initialize(trackCode);

const event = (label, action = Action.BUTTON, category = Category.EXTENSION) => ReactGA.event({
  category,
  action,
  label,
});

const page = (name) => {
  ReactGA.send({ hitType: 'pageview', page: name, title: name });
};

export default {
  init,
  event,
  page,
  Category,
  Action,
  Label,
  Page,
};
