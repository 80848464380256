import Path from 'path';
import { isInternetExplorer } from '../environment';

export const DEFAULT = 'DEFAULT';
export const ERROR = 'ERROR';
export const PROGRESS = 'PROGRESS';
export const PROTECT = 'PROTECT';
export const FOLDER = 'FOLDER';
export const FOLDER_SHARED = 'FOLDER_SHARED';

export const VIDEO = 'VIDEO';
export const IMAGE = 'IMAGE';
export const AUDIO = 'AUDIO';
export const WORD = 'WORD';
export const AE = 'AE';
export const AI = 'AI';
export const CSS = 'CSS';
export const DLL = 'DLL';
export const EXCEL = 'EXCEL';
export const HELP = 'HELP';
export const HTML = 'HTML';
export const ID = 'ID';
export const MP3 = 'MP3';
export const PDF = 'PDF';
export const PNG = 'PNG';
export const PPT = 'PPT';
export const PR = 'PR';
export const SKETCH = 'SKETCH';
export const SMI = 'SMI';
export const TXT = 'TXT';
export const VECTOR = 'VECTOR';
export const XD = 'XD';
export const WAV = 'WAV';
export const ZIP = 'ZIP';
export const TD = '3D';
export const PSD = 'PSD';

export const isThumbnail = name => {
    const ext = Path.extname(name)
        .toLowerCase()
        .slice(1);

    return /^(jpg|jpeg|bmp|png)$/.test(ext);
};

export const isImage = name => {
    const ext = Path.extname(name)
        .toLowerCase()
        .slice(1);

    return /^(jpg|jpeg|bmp|png|tiff|gif)$/.test(ext);
};

export const isVideo = name => {
    const ext = Path.extname(name)
        .toLowerCase()
        .slice(1);

    return /^(mp4|avi|flv|mkv|m4v|mpg|mov|wmv|mpeg|vod|tp|ts|asf|3gp|m3u8|swf|webm)$/.test(ext);
};

export const isAudio = name => {
    const ext = Path.extname(name)
        .toLowerCase()
        .slice(1);

    return /^(mp3|flac|wma|wav|aiff|m4a)$/.test(ext);
};

export const getExtType = name => {
    const ext = Path.extname(name)
        .toLowerCase()
        .slice(1);

    switch (ext) {
        case 'jpg':
        case 'jpeg':
            return IMAGE;
        case 'ppt':
        case 'pptx':
        case 'key':
            return PPT;
        case 'xls':
        case 'xlsx':
            return EXCEL;
        case 'pdf':
            return PDF;
        case 'doc':
        case 'docx':
            return WORD;
        case 'ae':
        case 'aaf':
        case 'aep':
        case 'aet':
        case 'aepx':
            return AE;
        case 'ai':
        case 'ait':
            return AI;
        case 'css':
        case 'scss':
        case 'saac':
            return CSS;
        case 'dll':
            return DLL;
        case 'hlp':
        case 'help':
            return HELP;
        case 'htm':
        case 'html':
        case 'js':
            return HTML;
        case 'indd':
            return ID;
        case 'mp3':
            return MP3;
        case 'png':
            return PNG;
        case 'psd':
            return PSD;
        case 'prproj':
            return PR;
        case 'skt':
        case 'sketch':
            return SKETCH;
        case 'srt':
        case 'smi':
            return SMI;
        case 'txt':
            return TXT;
        case 'xd':
            return XD;
        case 'svg':
        case 'dxf':
        case 'eps':
            return VECTOR;
        case 'rar':
        case 'tar':
        case 'alz':
        case 'zip':
            return ZIP;
        case '3ds':
        case '3mf':
        case 'blend':
        case 'dae':
        case 'fbx':
        case 'tif':
            return TD;
        case 'wav':
            return WAV;
        default:
            break;
    }

    if (isVideo(name)) {
        return VIDEO;
    }

    if (isImage(name)) {
        return IMAGE;
    }

    if (isAudio(name)) {
        return AUDIO;
    }

    return DEFAULT;
};

export const Types = {
    DEFAULT,
    ERROR,
    PROGRESS,
    PROTECT,

    FOLDER,
    FOLDER_SHARED,

    VIDEO,
    IMAGE,
    AUDIO,
    WORD,

    AE,
    AI,
    CSS,
    DLL,
    EXCEL,
    HELP,
    HTML,
    ID,
    MP3,
    PDF,
    PSD,
    PNG,
    PPT,
    PR,
    SKETCH,
    SMI,
    TXT,
    VECTOR,
    XD,
    ZIP,
};

function isDirectory(entry) {
    return entry.isDirectory;
}

function isFile(entry) {
    return entry.isFile;
}

const readEntryContentAsync = entry =>
    new Promise((resolve, reject) => {
        let reading = 0;
        const contents = [];

        function readEntry(entry) {
            if (isFile(entry)) {
                reading++;
                entry.file(file => {
                    reading--;
                    const fullPath = entry.fullPath;
                    //                console.log(fullPath)
                    file.fullPath = fullPath.startsWith('/') ? fullPath.slice(1) : fullPath;
                    contents.push(file);

                    if (reading === 0) {
                        resolve(contents);
                    }
                });
            } else if (isDirectory(entry)) {
                readReaderContent(entry.createReader());
            }
        }

        function readReaderContent(reader) {
            reading++;

            reader.readEntries(function(entries) {
                reading--;
                if (entries.length === 0) {
                    if (reading === 0) {
                        resolve(contents);
                    }
                } else {
                    for (const entry of entries) {
                        readEntry(entry);
                    }
                    readReaderContent(reader);
                }
            });
        }

        readEntry(entry);
    });

export const getFilesFromDataTransferAsync = async files => {
    const tasks = [];
    for (let i = 0; i < files.length; i++) {
        const item = files[i];
        tasks.push(
            new Promise(async resolve => {
                if (item.kind === 'file') {
                    if (typeof item.webkitGetAsEntry === 'function') {
                        const entry = item.webkitGetAsEntry();
                        window.debug.log(entry);
                        resolve(await readEntryContentAsync(entry));
                    } else {
                        const file = item.getAsFile();
                        if (file) {
                            resolve([file]);
                        } else {
                            resolve([]);
                        }
                    }
                }
            }),
        );
    }

    const results = await Promise.all(tasks);
    return results.reduce((sum, item) => sum.concat(item), []);
};

export function traverseDirectory(entry) {
    const reader = entry.createReader();
    // Resolved when the entire directory is traversed
    return new Promise((resolve, reject) => {
        const iterationAttempts = [];
        function readEntries() {
            // According to the FileSystem API spec, readEntries() must be called until
            // it calls the callback with an empty array.  Seriously??
            reader.readEntries(
                entries => {
                    if (!entries.length) {
                        // Done iterating this particular directory
                        resolve(Promise.all(iterationAttempts));
                    } else {
                        // Add a list of promises for each directory entry.  If the entry is itself
                        // a directory, then that promise won't resolve until it is fully traversed.
                        iterationAttempts.push(
                            Promise.all(
                                entries.map(ientry => {
                                    if (ientry.isFile) {
                                        // DO SOMETHING WITH FILES
                                        return ientry;
                                    }
                                    // DO SOMETHING WITH DIRECTORIES
                                    return traverseDirectory(ientry);
                                }),
                            ),
                        );
                        // Try calling readEntries() again for the same dir, according to spec
                        readEntries();
                    }
                },
                error => reject(error),
            );
        }
        readEntries();
    });
}

export const translateFileList = list => {
    if (isInternetExplorer()) {
        const fileList = [];
        for (let i = 0; i < list.length; ++i) {
            fileList.push(list[i]);
        }
        return fileList;
    } else {
        return list;
    }
};
