export default {
    SIGNIN_SUCCESS: 'extension_signin_success',
    SIGNIN_FAIL: 'extension_signin_fail',
    TOTAL_SUCCESS: 'extension_total_success',
    GOOGLE_SUCCESS: 'extension_google_success',
    GOOGLE_FAIL: 'extension_google_fail',
    FACEBOOK_SUCCESS: 'extension_facebook_success',
    FACEBOOK_FAIL: 'extension_facebook_fail',
    FORGOT_PASSWORD: 'extension_forgotpassword',
    SIGNUP_NOW: 'extension_signup_now',
    CLOSE: 'extension_close',
    ADD_FROM_COMPUTER: 'extension_add_from com',
    ADD_FROM_CLOUD: 'extension_add_from_cloud',
    ADD_FROM_COMPUTER_ICON: 'extension_add_from com_icon',
    ADD_FROM_CLOUD_ICON: 'extension_add_from_cloud_icon',
    PROFILE: 'extension_profile',
    ATTACH: 'extension_attach',
    DELETE: 'extension_delete',
    LINKINFO_COPY: 'extension_linkinfo_copy',

    MY_SENDY_BACK: 'extension_my_sendy_back',
    SIGNOUT: 'extension_sign_out',
    MY_LINK: 'extension_my_link',
    MY_CLOUD: 'extension_my_cloud',
    GMAIL_ON: 'extension_gmail_toggle_on',
    GMAIL_OFF: 'extension_gmail_toggle_off',
    SLACK_ON: 'extension_slack_toggle_on',
    SLACK_OFF: 'extension_slack_toggle_off',
    CHATWORK_ON: 'extension_chatwork_toggle_on',
    CHATWORK_OFF: 'extension_chatwork_toggle_off',

    RESET: 'extension_file_reset',
    SAVE_CLOUD_ON: 'extension_save_cloud_on',
    SAVE_CLOUD_OFF: 'extension_save_cloud_off',
    SET_PASSWORD: 'extension_set_password',
    COMMENT_ON: 'extension_comment_on',
    COMMENT_OFF: 'extension_commnet_off',
    SEND: 'extension_send',
};
