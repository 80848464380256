export const IS_DEV =
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'development' ||
    localStorage.getItem('please_debug_me') ||
    sessionStorage.getItem('please_debug_me');

let _IS_IE = false;
let _IS_WEBKIT = false;
var agent = navigator.userAgent.toLowerCase();
_IS_IE = (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) || agent.indexOf('msie') !== -1;
_IS_WEBKIT = /webkit/.test(agent);

export const isInternetExplorer = () => _IS_IE;
export const isWebKit = () => _IS_WEBKIT;
