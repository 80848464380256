import AWS from 'aws-sdk';
import Path from 'path';
import Common from './common';
import { Errors, ServiceInfo } from '../constants';
import { stringNormalize, addZero } from '../utils';

const apiVersion = '2019-04-01';
const s3Param = (credentials, bucket = null) => ({
    apiVersion,
    sslEnabled: true,
    region: process.env.REACT_APP_S3_REGION,
    ...credentials,
    params: {
        Bucket: bucket || process.env.REACT_APP_S3_BUCKET,
    },
});

const SERVICE = 'file';

const implement = (block, v = 1) => Common.implement(ServiceInfo.cloudApiUrl, SERVICE, v, block);
//const keyPath = _ => store.getState().account.userInfo.uid

const _getTempCredential = _ =>
    implement(instance =>
        instance.get('filelink/token', {
            params: {
                host_id: Common.getId(),
                path: 'hello',
            },
        }),
    );

const getTempCredential = async () => {
    const result = await _getTempCredential();
    return {
        accessKeyId: result.data.AccessKeyId,
        secretAccessKey: result.data.SecretAccessKey,
        sessionToken: result.data.SessionToken,
    };
};

const upload = (fileList, hostId, keyPath = '', progressCallback = null, bucket = null) =>
    Common.handleError(
        _ =>
            new Promise(async (resolve, reject) => {
                let canceled = false;
                try {
                    const files = fileList.slice(0);
                    //            console.log(files.map(item => stringNormalize(item.fullPath || item.webkitRelativePath || item.name)))
                    // const checkResult = await Common.handleError(() =>
                    //     Browse.checkUpload(
                    //         hostId,
                    //         path,
                    //         files.map(item => ({
                    //             path: stringNormalize(item.fullPath || item.webkitRelativePath || item.name),
                    //             size: item.size,
                    //         })),
                    //     ),
                    // );
                    // window.debug.log(checkResult.data);
                    // if (!checkResult.data || !checkResult.data.data || !checkResult.data.data.file) {
                    //     throw new Error('Invalid');
                    // }
                    // const resFiles = checkResult.data.data.file;
                    // for (let i = 0; i < resFiles.length; ++i) {
                    //     files[i].fullPath = resFiles[i].path;
                    // }

                    const s3 = new AWS.S3(s3Param(await getTempCredential(), bucket));
                    const totalSize = files.reduce((value, item) => value + item.size, 0);
                    const totalCount = files.length;

                    const alreadySet = new Set();
                    const newFolderTask = item =>
                        new Promise((resolve, reject) => {
                            const fullKey = Path.join(keyPath, item, '/');
                            const param = {
                                Key: stringNormalize(fullKey),
                            };
                            s3.putObject(param, (error, data) => {
                                if (error) {
                                    Common.managedReject(reject, error, {
                                        result: false,
                                        code: Errors.UPLOAD_CREATE_FOLDER,
                                        error,
                                    });
                                } else {
                                    Common.managedResolve(resolve, data, {
                                        result: true,
                                        data,
                                    });
                                }
                            });
                        });
                    let count = 0;
                    let currentSize = 0;
                    const progressHandler = obj => evt => {
                        if (progressCallback) {
                            canceled = canceled || progressCallback(totalSize, currentSize + evt.loaded, totalCount, count);
                            if (canceled) {
                                obj.abort();
                            }
                        }
                    };
                    for (const item of files) {
                        const key = item.fullPath || item.name;
                        const fullKey = stringNormalize(Path.join(keyPath, key));
                        window.debug.log(fullKey);
                        const params = {
                            Key: fullKey,
                            ContentType: item.type,
                            Body: item,
                        };
                        let dirname = Path.dirname(stringNormalize(key));
                        while (dirname !== '.') {
                            if (!alreadySet.has(dirname)) {
                                try {
                                    await newFolderTask(dirname);
                                    alreadySet.add(dirname);
                                } catch (error) {
                                    window.debug.log(error);
                                    return Common.managedReject(reject, error, {
                                        result: false,
                                        code: Errors.UPLOAD_CREATE_FOLDER,
                                        error,
                                    });
                                }
                            }
                            dirname = Path.dirname(dirname);
                        }
                        const s3obj = s3.upload(params);
                        await s3obj.on('httpUploadProgress', progressHandler(s3obj)).promise();
                        ++count;
                        currentSize += item.size;
                        if (progressCallback) {
                            progressCallback(totalSize, currentSize, totalCount, count);
                        }
                    }
                    if (progressCallback) {
                        canceled = canceled || progressCallback(totalSize, totalSize, totalCount, totalCount);
                    }
                    Common.managedResolve(resolve, null);
                } catch (error) {
                    window.debug.log(error);
                    const response = Common.extractResponse(error);
                    if (response) {
                        const message = response.data ? response.data.error : null;
                        switch (message) {
                            case 'SEDNY_ERR_EXCEEDED_FOLDER_MAX_STORAGE':
                                Common.managedReject(reject, error.error, { code: Errors.STORAGE_LIMIT_EXCEEDED });
                                break;
                            case 'SENDY_ERR_FILE_NO_SUCH_KEY':
                                Common.managedReject(reject, error, { code: Errors.NOT_FOUND });
                                break;
                            default:
                                Common.managedReject(reject, error, {
                                    ...(canceled ? { code: Errors.USER_ABORTED } : null),
                                });
                                break;
                        }
                    } else {
                        Common.managedReject(reject, error, {
                            ...(canceled ? { code: Errors.USER_ABORTED } : null),
                        });
                    }
                }
            }),
    );

const downloadFile = (path, keyPath = 'RbMSaoFrwCScy5Noh4nhf7WOSlu1', bucket = null) =>
    Common.handleError(
        _ =>
            new Promise(async (resolve, reject) => {
                try {
                    const s3 = new AWS.S3(s3Param(await getTempCredential(), bucket));
                    const params = {
                        Key: Path.join(keyPath, path),
                    };
                    window.debug.log('Downloading: ', Path.join(keyPath, path));
                    s3.getObject(params, (err, data) => {
                        if (err) {
                            reject(err);
                        } else {
                            data.filename = Path.basename(path);
                            resolve(data);
                        }
                    });
                } catch (error) {
                    window.debug.log('error', error);
                    reject(error);
                }
            }),
    );

const DOWNLOADER_SERVICE = 'zipdownloader';
const DOWNLOADER_VERSION = 1;

const downloader_implement = block =>
    Common.implement(DOWNLOADER_SERVICE, DOWNLOADER_VERSION, block, {
        responseType: 'blob',
    });

const downloadFiles = (hostId, prefixPath, files) =>
    downloader_implement(instance =>
        instance.post('download', {
            host_id: hostId || Common.getId(),
            file: files,
            path: prefixPath,
        }),
    );

const getCopyPath = title => {
    const maxLength = 17;
    if (title.length > maxLength) title = title.substring(0, maxLength) + '...';

    const now = new Date();
    let path = 'Saved my link/';
    path +=
        now
            .getFullYear()
            .toString()
            .substring(2, 4) + '-';
    path += addZero(now.getMonth() + 1) + '-';
    path += addZero(now.getDate()) + ' ';
    path += addZero(now.getHours()) + ':';
    path += addZero(now.getMinutes()) + ':';
    path += addZero(now.getSeconds()) + '_';
    path += title;

    return path;
};

export default {
    s3: {
        upload,
        downloadFile,
        downloadFiles,
        getCopyPath,
    },
};
