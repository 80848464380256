import Actions from '../actions';

export default (state = {}, action) => {
    switch (action.type) {
        case Actions.ACTION_UPDATE_SETTING:
            return Object.assign({}, state, action.settings);
        default:
            return state;
    }
};
