// import * as navigation from './navigation';
import * as Account from './account';
// import * as storage from './storage';
// import * as selection from './selection';
// import * as setting from './setting';
import * as Status from './status';
// import * as details from './details';
// import * as pathlist from './pathlist';
import * as ProfileCache from './profile-cache';
// import * as explore from './explore'
// import * as view from './view'
import * as Sent from './sent';
import * as Setting from './setting';
import * as Data from './data';

export default {
    // ...navigation,
    ...Account,
    // ...storage,
    // ...selection,
    // ...setting,
    ...Status,
    // ...details,
    // ...pathlist,
    ...ProfileCache,
    // ...explore,
    // ...view,
    ...Sent,
    ...Setting,
    ...Data,
};
