import Actions from '../actions';

export default (state = {}, action) => {
    switch (action.type) {
        case Actions.SET_AUTHINFO:
            window.debug.log(state, action);
            return Object.assign({}, state, {
                userInfo: {
                    ...state.userInfo,
                    uid: action.uid,
                    name: action.name,
                    email: action.email,
                    image: action.image,
                    verified: action.verified,
                    idToken: action.idToken,
                },
            });
        case Actions.UPDATE_ID_TOKEN:
            return Object.assign({}, state, {
                userInfo: {
                    ...state.userInfo,
                    idToken: action.token,
                },
            });
        case Actions.UPDATE_ACCOUNT_SETTINGS:
            return Object.assign({}, state, {
                settings: {
                    ...state.settings,
                    account: {
                        ...(action.merge ? state.settings.account : null),
                        ...action.info,
                    },
                },
            });
        case Actions.UPDATE_CLOUD_SETTINGS:
            return Object.assign({}, state, {
                settings: {
                    ...state.settings,
                    cloud: {
                        ...(action.merge ? state.settings.cloud : null),
                        ...action.info,
                    },
                },
            });
        default:
            return state;
    }
};
