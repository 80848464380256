export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

export const updateProfile = (dispatch, id, profile) => {
    dispatch({
        type: UPDATE_PROFILE,
        id,
        profile,
    });
};

export const deleteProfile = (dispatch, id) => {
    dispatch({
        type: DELETE_PROFILE,
        id,
    });
};

export const clearProfile = dispatch => {
    dispatch({
        type: CLEAR_PROFILE,
    });
};
