import Task from './Task';
import API from '../../api';

export default class DeleteKeyTask extends Task {
    key;

    constructor(store, key, events) {
        super(store, events);
        this.key = key;
    }

    process = async _ => await API.transfer.key.remove(this.key);
}
