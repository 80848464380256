// for debug
export const SET_AUTHINFO = 'SET_AUTHINFO';
export const UPDATE_ID_TOKEN = 'UPDATE_ID_TOKEN';
export const UPDATE_ACCOUNT_SETTINGS = 'UPDATE_ACCOUNT_SETTINGS';
export const UPDATE_CLOUD_SETTINGS = 'UPDATE_CLOUD_SETTINGS';

export const setAuthInfo = (dispatch, { uid, name, email, image, verified, idToken }) => {
    dispatch({
        type: SET_AUTHINFO,
        uid,
        name,
        email,
        image,
        verified,
        idToken,
    });
};

export const updateIdToken = (dispatch, token) => {
    dispatch({
        type: UPDATE_ID_TOKEN,
        token,
    });
};

export const updateAccountSetting = (dispatch, info, merge) => {
    dispatch({
        type: UPDATE_ACCOUNT_SETTINGS,
        info,
        merge,
    });
};

export const updateCloudSetting = (dispatch, info, merge) => {
    dispatch({
        type: UPDATE_CLOUD_SETTINGS,
        info,
        merge,
    });
};
