import { IS_DEV } from './environment';

console.log(process.env.NODE_ENV);

const __no_op = function() {};
const bindIf = func => (func ? func.bind(window.console) : window.console.log.bind(window.console));

if (IS_DEV) {
    window.debug = {
        log: bindIf(console.log),
        error: bindIf(console.error),
        info: bindIf(console.info),
        warn: bindIf(console.warn),
        assert: bindIf(console.assert),
        count: bindIf(console.count),
        table: bindIf(console.table),
        trace: bindIf(console.trace),
        time: bindIf(console.time),
        timeEnd: bindIf(console.timeEnd),
    };
    window.release = {
        log: __no_op,
        error: __no_op,
        info: __no_op,
        warn: __no_op,
        assert: __no_op,
        count: __no_op,
        table: __no_op,
        trace: __no_op,
        time: __no_op,
        timeEnd: __no_op,
    };
} else {
    window.debug = {
        log: __no_op,
        error: __no_op,
        info: __no_op,
        warn: __no_op,
        assert: __no_op,
        count: __no_op,
        table: __no_op,
        trace: __no_op,
        time: __no_op,
        timeEnd: __no_op,
    };
    window.release = {
        log: bindIf(console.log),
        error: bindIf(console.error),
        info: bindIf(console.info),
        warn: bindIf(console.warn),
        assert: bindIf(console.assert),
        count: bindIf(console.count),
        table: bindIf(console.table),
        trace: bindIf(console.trace),
        time: bindIf(console.time),
        timeEnd: bindIf(console.timeEnd),
    };
}
