import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import * as indexedDB from '../utils/indexedDB';
import * as localStorage from '../utils/localStorage';

import Common from '../api/common';

const http = axios.create({
  baseURL: `${process.env.REACT_APP_HOST || ''}/api`,
});

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

export default class Firebase {
    getFirebaseUser = async () => {
      try {
        const user = localStorage.getAuthUserFromlocalStorage();
        if (user) {
          return user;
        }
        return await indexedDB.getAuthUserFromIndexedDB();
      } catch (error) {
        window.debug.log('getFirebaseUser Error', error);
        throw error;
      }
    };

    setFirebaseUser = async (user) => {
      try {
        const haveUser = localStorage.getAuthUserFromlocalStorage();
        if (haveUser) {
          return this.setIndexeDBPersistence(user);
        }
        await this.setIndexeDBPersistence(user);
      } catch (error) {
        window.debug.log('set FirebaseUser Error', error);
        throw error;
      }
    };

    signInWithCustomTokenCustom = async (token) => {
      try {
        const auth = firebase.auth();
        return await auth.signInWithCustomToken(token);
      } catch (error) {
        window.debug.log('signInWithCustomTokenCustom Error', error);
        throw error;
      }
    }

    doSignInWithEmailAndPassword = (email, password, token) => Common.handleError(() => http({
      method: 'POST',
      url: '/auth/signin',
      data: {
        email,
        password,
        token,
      },
    }));

    SocialApisLoads = async () => {
      try {
        // PCS-3121
        // await this.googleApiLoad();
        if (!firebase.apps.length) {
          firebase.initializeApp(config);
        }
        await this.facebookInitialize();
      } catch (error) {
        throw error;
      }
    };

    // PCS-3121
    // googleApiLoad = () => new Promise((resolve, reject) => {
    //   window.gapi.load('client:auth2', () => {
    //     window.auth2 = window.gapi.client
    //       .init({
    //         clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    //         scope: 'profile',
    //       })
    //       .then(
    //         () => {
    //           resolve(true);
    //         },
    //         (reason) => {
    //           reject(reason);
    //         },
    //       );
    //   });
    // });

    // googleLogin = () => new Promise((resolve, reject) => {
    //   window.gapi.auth2
    //     .getAuthInstance()
    //     .signIn()
    //     .then(
    //       (googleUser) => {
    //         const email = googleUser.getBasicProfile().getEmail();
    //         const token = googleUser.getAuthResponse().id_token;

    //         if (email && token) {
    //           resolve({
    //             token,
    //             email,
    //           });
    //         } else {
    //           reject('INVALID_PARAMS');
    //           // TODO 에러 메시지 처리
    //         }
    //       },
    //       (reason) => {
    //         reject(reason);
    //       },
    //     );
    // });

    googleLogin = () => new Promise((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          const credential = result.credential || null;
          const userEmail = result.user.email || null;
          const idToken = credential.idToken || null;
          // console.log(credential);
          if (userEmail && idToken) {
            resolve({
              token: idToken,
              email: userEmail,
            });
          } else {
            reject('INVALID_PARAMS');
          }
        })
        .catch((error) => {
          const errorMessage = error.message;
          reject(errorMessage);
        });
    });

    doSignInWithOauth = (email, oauth_token, provider) => Common.handleError(() => http({
      method: 'POST',
      url: '/account/oauth',
      data: {
        email,
        provider,
        oauth_token,
        extensions: true,
      },
    }));

    getUserFirebaseByUID = uid => Common.handleError(() => http({
      method: 'POST',
      url: '/auth/get-firebase-user-by-uid',
      data: {
        uid,
      },
    }));


    facebookInitialize = () => {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v8.0',
      });
    };

    facebookLogin = () => new Promise((resolve, reject) => {
      window.FB.getLoginStatus(async (response) => {
        if (response.status === 'connected') {
          const data = await this.getFacebookUserInfo(response.authResponse.accessToken);
          resolve(data);
        } else {
          window.FB.login(async (res) => {
            if (res && res.authResponse) {
              const data = await this.getFacebookUserInfo(res.authResponse.accessToken);
              resolve(data);
            }
          }, { scope: 'email' });
        }
      });
    });

    getFacebookUserInfo = token => new Promise((resolve, reject) => {
      window.FB.api('/me', { fields: 'email' }, (response) => {
        if (token && response.email) {
          resolve({
            token,
            email: response.email,
          });
        } else {
          reject('error');
        }
      });
    });

    doSignOut = async () => {
      try {
        if (localStorage.getAuthUserFromlocalStorage()) {
          return localStorage.clearAuthUserInLocalStorage();
        }
        return await indexedDB.clearIndexedDB();
      } catch (error) {
        window.debug.log('Firebase doSignOut Error', error);
        throw error;
      }
    };

    setIndexeDBPersistence = async user => await indexedDB.setAuthUserInIndexedDB(user);

    updateProfile = async (profile) => {
      const user = await this.getFirebaseUser();
      try {
        return Common.handleError(() => http({
          method: 'POST',
          url: '/account/profile',
          data: {
            idToken: user.idToken,
            profile,
          },
        }));
      } catch (error) {
        throw error;
      }
    };

    unregister = async () => {
      try {
        const user = await this.getFirebaseUser();
        await Common.handleError(() => http({
          method: 'DELETE',
          url: '/account/unregister',
          data: {
            user_id: user.uid,
          },
        }));
        await this.doSignOut();
        return;
      } catch (error) {
        window.debug.log('unregister', error);
        throw error;
      }
    };

    refreshIdToken = async () => {
      try {
        const user = await this.getFirebaseUser();

        const { data: { data: result } } = await Common.handleError(() => http({
          method: 'POST',
          url: '/account/refreshtoken',
          data: {
            refresh_token: user.refreshToken,
          },
        }));

        await this.setFirebaseUser(result);
        return result.idToken;
      } catch (error) {
        window.debug.log('refreshIdToken', error);
        await this.doSignOut();
        window.location.reload();
      }
    };

    get now() {
      return new Date().getTime();
    }
}
