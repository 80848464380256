import { Operations } from '../operations';

export const AccessLevel = {
    VIEWER: {
        id: 'viewer',
    },
    DOWNLOADER: {
        id: 'downloader',
        canRead: true,
    },
    UPLOADER: {
        id: 'uploader',
        canRead: true,
        canWrite: true,
    },
    EDITOR: {
        id: 'editor',
        canRead: true,
        canWrite: true,
        canDelete: true,
    },
};

function checkOperationIsValid(accessLevel, operationId) {
    switch (operationId) {
        case Operations.SEND.id:
        case Operations.DOWNLOAD.id:
            return accessLevel.canRead;

        case Operations.UPLOAD.id:
        case Operations.UPLOAD_FOLDER.id:
        case Operations.ADD_COMMENT.id:
        case Operations.NEW_FOLDER.id:
        case Operations.COPY.id:
        case Operations.RENAME.id:
            return accessLevel.canWrite;

        case Operations.DELETE.id:
        case Operations.MOVE.id:
        case Operations.SHARE.id:
            return accessLevel.canDelete;

        default:
            return true;
    }
}

Object.values(AccessLevel).forEach(item => {
    item.isValidOperation = operationId => checkOperationIsValid(item, operationId);
});

export const getAccessLevel = id => {
    return id ? AccessLevel[id.toUpperCase()] : undefined;
};

export const accessLevelList = Object.values(AccessLevel).map(item => item.id);
