import React from 'react';
import { compose } from 'redux';
import { withStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import SendyLoading from '../assets/image/sendy_loading.gif';
import { withTranslation, Trans } from 'react-i18next';
import * as Colors from '@bit/estmob.frontier-components.colors';

const styles = {
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
    },
    inner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 0 15% 0',

        '&>img': {
            maxWidth: '90px',
            maxHeight: '90px',
        },

        '&>span:nth-of-type(1)': {
            margin: '5px 0 4px 0',
        },
    },
};

const GlobalIndicator = props => {
    const { classes, className } = props;
    return (
        <div className={clsx(classes.root, className)}>
            <span className={classes.inner}>
                <img src={SendyLoading} alt="" />
                <Typography
                    component="span"
                    variant="h3"
                    style={{
                        color: Colors.GRAY7,
                    }}
                >
                    <Trans i18nKey="global_loading_message" />
                </Typography>
                <Typography
                    component="span"
                    variant="body1"
                    style={{
                        color: Colors.GRAY5,
                    }}
                >
                    <Trans i18nKey="global_loading_message_sub" />
                </Typography>
            </span>
        </div>
    );
};

export default compose(
    withTranslation(),
    withStyles(styles),
)(GlobalIndicator);
