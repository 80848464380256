import { AUTH_KEY } from '../constants';

export const getAuthUserFromlocalStorage = () => {
    return JSON.parse(localStorage.getItem(AUTH_KEY));
}

export const setAuthUserInLocalStorage = (user) => {
    user.storage = 'local';
    return localStorage.setItem(AUTH_KEY, JSON.stringify(user));
}

export const clearAuthUserInLocalStorage = () => {
    return localStorage.removeItem(AUTH_KEY);
}