import Common from '../common';
import { ServiceInfo, TRANSFER_OBJECT_TYPE } from '../../constants';
import axios from 'axios';
import { deepAssign } from '../../utils';
import Url from 'url-parse';
const SERVICE = 'transfer';
const VERSION = 1;
const implement = (block, url = ServiceInfo.cloudApiUrl) => Common.implement(url, SERVICE, VERSION, block);

const uploadFromCloud = (params, url) => implement(instance => instance.post('/cloud/copy', params), url);

const check = (key, url) => implement(instance => instance.post('/check', { key }), url);

const cancel = key => implement(instance => instance.post('/status/cancel', { key }));

export class RelayServer {
    transferObject;
    transferType;
    uid = _ => this.store.getState().account.userInfo.uid;
    idToken = _ => this.store.getState().account.userInfo.idToken;

    constructor(server, store) {
        this.server = server;
        this.store = store;
    }

    call = async (block, auth = false, param) =>
        await Common.handleError(
            _ =>
                new Promise(async (resolve, reject) => {
                    const options = deepAssign(
                        {
                            baseURL: this.server,
                            timeout: 60000,
                            headers: {
                                'Content-Type': 'text/plain',
                                ...(auth === true ? { Authorization: `Bearer ${this.idToken()}` } : null),
                            },
                        },
                        param,
                    );
                    const socket = axios.create(options);
                    try {
                        const result = await block(socket);
                        window.debug.log(result);
                        Common.managedResolve(resolve, result);
                    } catch (error) {
                        Common.managedReject(reject, error);
                    }
                }),
            0,
            auth ? 3 : 0,
        );

    putState = (key, transfer_id, state, sent) => this.call(instance => instance.put(`/s3/${key}?mode=status&transfer_id=${transfer_id}`, { state, sent }));

    createCredit = async bucket => {
        const data = Common.extractData(await this.call(instance => instance.get('/v1/storage/token'), true));
        if (data) {

            if (data.sas_url) {
                const sasUrl = new Url(data.sas_url, false);
                this.transferType = TRANSFER_OBJECT_TYPE.azureContainer;
                return {
                    accountStorageUrl: sasUrl.origin,
                    containerName: sasUrl.pathname.replace('/', ''),
                    sas: sasUrl.query,
                }
            } else {
                this.transferType = TRANSFER_OBJECT_TYPE.s3Object;
                return {
                    accessKeyId: data.AccessKeyId,
                    secretAccessKey: data.SecretAccessKey,
                    sessionToken: data.SessionToken,
                };
            }
        } else {
            return null;
        }
    };

    start = (key, transfer_id, files) =>
        this.call(instance => instance.post(`/v1/link/${key}/upload/start`, { host_id: this.uid(), files, transfer_id, link_flags: 'link', os_type: 'web', app_version: process.env.REACT_APP_SENDY_VERSION }));
        
    check = (key, transfer_id) =>
        this.call(instance => instance.post(`/v1/link/${key}/upload/check`, { transfer_id }));

    finish = (key, transferId, state, param) => this.call(instance => instance.post(`/v1/link/${key}/upload/finish`, { transfer_id: transferId, os_type: 'web', state, app_version: process.env.REACT_APP_SENDY_VERSION, ...param } ));
}

export default {
    relay: {
        uploadFromCloud,
        check,
        cancel,
    },
};
