import Common from '../common';
import Browse from './browse';
import { ServiceInfo } from '../../constants';

const SERVICE = 'file';

const implement = (block, v = 1) => Common.implement(ServiceInfo.cloudApiUrl, SERVICE, v, block);

const getTrash = (pathToGet, param = {}) =>
    implement(instance =>
        instance.post('trash', {
            host_id: Common.getId(),
            path: Common.preprocessFolderPath(pathToGet),
            ...param,
        }),
    );

const restoreFiles = fileDataList =>
    implement(async instance => {
        const response = await instance.put('trash/restore', {
            host_id: Common.getId(),
            file: fileDataList.map(item => ({
                path: item.path,
                version_id: item.versionId,
            })),
        });
        const data = Common.extractData(response);
        if (data && data.key) {
            await Browse.checkUntil(instance, data.key);
            window.debug.log(response);
            return response;
        }
        window.debug.log(response);
        return response;
    }, 2);

const deleteFilesPermanently = fileDataList =>
    implement(async instance => {
        const response = await instance.delete('trash', {
            data: {
                host_id: Common.getId(),
                file: fileDataList.map(item => ({
                    path: item.path,
                    version_id: item.versionId,
                })),
            },
        });
        const data = Common.extractData(response);
        if (data && data.key) {
            await Browse.checkUntil(instance, data.key);
            window.debug.log(response);
            return response;
        }
        window.debug.log(response);
        return response;
    }, 2);

const emptyTrash = () =>
    implement(async instance => {
        const response = await instance.delete('trash/empty', {
            data: {
                host_id: Common.getId(),
            },
        });
        const data = Common.extractData(response);
        if (data && data.key) {
            await Browse.checkUntil(instance, data.key);
            window.debug.log(response);
            return response;
        }
        window.debug.log(response);
        return response;
    }, 2);

export default {
    getTrash,
    restoreFiles,
    deleteFilesPermanently,
    emptyTrash,
};
