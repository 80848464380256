/*eslint no-extend-native: "off"*/

import Path from 'path';

String.prototype.format = function() {
    let a = this;
    for (const k in arguments) {
        a = a.replace('{' + k + '}', arguments[k]);
    }
    return a;
};

String.prototype.removePrefix = function(value) {
    let a = this;
    if (a.indexOf(value) === 0) {
        return this.slice(value.length);
    }
    return a;
};

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function readableSize(size, separator = ' ') {
    var i = Math.floor(Math.log(size) / Math.log(1024));
    let val = (size / Math.pow(1024, i)).toFixed(2) * 1;
    if (isNaN(val)) {
        i = 0;
        val = 0;
    }
    return val + separator + ['Bytes', 'kB', 'MB', 'GB', 'TB'][i];
}

export function rootPath(path) {
    const split = path.split(Path.sep).filter(item => item);

    return split.length > 0 ? split[0] + Path.sep : '';
}

export const stringNormalize = s => {
    return s && !!String.prototype.normalize ? s.normalize() : s;
};
