import API from '../../api';
import DeleteHistoryTask from './DeleteHistoryTask';
import DeleteKeyTask from './DeleteKeyTask';
import PromiseQueue from 'promise-queue';
import { SendTask } from '.';

class TransferManager {
    taskQueue = new PromiseQueue(1, Infinity);
    activeTransfer = 0;

    constructor(store) {
        this.store = store;
    }

    _processTask = async (task, events) => {
        return new Promise((resolve, reject) => {
            this.taskQueue
                .add(async function() {
                    events && events.onStart && events.onStart(task);
                    try {
                        const result = await task.process();
                        resolve(result);
                        return result;
                    } catch (error) {
                        reject(error);
                        return error;
                    }
                })
                .then(result => {
                    events && events.onFinish && events.onFinish(task, result);
                })
                .catch(error => {
                    window.debug.error(error);
                });
        });
    };

    deleteKey = async ({ key, created_at, expires_at }, events) => {
        if (API.transfer.link.isExpired(expires_at)) {
            return await this._processTask(new DeleteHistoryTask(this.store, created_at, events), events);
        } else {
            return await this._processTask(new DeleteKeyTask(this.store, key, events), events);
        }
    };

    send = async (sourceType, files, options, events, token) => {
        this.activeTransfer++;
        try {
            return await this._processTask(new SendTask(this.store, sourceType, files, options, events, token), events);
        } finally {
            this.activeTransfer--;
        }
    };
}

export default TransferManager;
