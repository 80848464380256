// import axios from 'axios';
import Common from '../common';
import { Errors, ServiceInfo } from '../../constants';

const SERVICE_TYPE_CLOUD = 'cloud';

const implement = block => Common.implement(ServiceInfo.otherApiUrl, 'comment', 1, block);

const getFileComments = (path, hostId, service = SERVICE_TYPE_CLOUD) =>
    implement(instance =>
        instance.post('comments/list', {
            host_id: hostId,
            service,
            path,
        }),
    );

const postFileComment = (path, hostId, content, parentId = null, service = SERVICE_TYPE_CLOUD) =>
    implement(instance =>
        instance.post('comments', {
            host_id: hostId,
            path,
            parent_id: parentId,
            content,
            service,
        }),
    );

const modifyFileComment = (commentId, content, service = SERVICE_TYPE_CLOUD) =>
    implement(instance =>
        instance.post(`comments/${commentId}`, {
            service,
            content,
        }),
    );

const deleteFileComment = (commentId, service = SERVICE_TYPE_CLOUD) =>
    implement(instance =>
        instance
            .delete(`comments/${commentId}`, {
                params: {
                    service,
                },
            })
            .catch(error => {
                switch (error.response.status) {
                    case 500:
                        if (error.response.data && error.response.data.error) {
                            switch (error.response.data.error) {
                                case 'SENDY_ERR_NOT_FOUND':
                                    throw Common.managedReject(null, error, { code: Errors.COMMENT_NOT_FOUND });
                                default:
                                    throw error;
                            }
                        } else {
                            throw error;
                        }
                    case 404:
                        throw Common.managedReject(null, error, { code: Errors.COMMENT_NOT_FOUND });
                    default:
                        throw error; //Common.managedReject(null, error, { code: Errors.NOT_FOUND } )
                }
            }),
    );

export default {
    getFileComments,
    postFileComment,
    deleteFileComment,
    modifyFileComment,
};
