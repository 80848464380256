import API, { refreshUserInfo } from './api';
import { AdditionalPages, DEVICE_ID, Pages } from './constants';
import { OperationProcessor, OperationProcessorContext } from './operations';
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { TransferManager, UploadManager } from './managers';
import { clearAuthInfo, finalizeStorage, initStorage, redirectToError, safe, signOut, wasRestored } from './utils';

import Actions from './redux/actions';
import ConditionalRoute from './middlewares/ConditionalRoute';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import GlobalIndicator from './components/GlobalIndicator';
import moment from 'moment';
import { store } from './Root';

// import NotificationSystem from 'react-notification-system'

const UserInfoState = {
    Undefined: 'undefined',
    None: 'none',
    Updating: 'updating',
    Updated: 'updated',
};

class App extends React.Component {
    state = {
        initialized: wasRestored(),
    };

    uploadManager = new UploadManager(store);
    transferManager = new TransferManager(store);
    userInfoState = UserInfoState.Undefined;

    handleTokenRefreshed = async user => {
        try {
            if (user) {
                const { displayName = '', email = '', photoURL = '', emailVerified = false, uid, idToken } = user;
    
                Actions.setAuthInfo(store.dispatch, {
                    name: displayName,
                    uid,
                    email,
                    image: photoURL,
                    verified: emailVerified,
                    idToken: idToken,
                });
                switch (this.userInfoState) {
                    case UserInfoState.Undefined:
                        await this.updateUserInfo(uid, true);
                        break;
                    case UserInfoState.Updated:
                        this.setInitialized(true);
                        break;
                    case UserInfoState.None:
                        this.setInitialized(false);
                        await this.updateUserInfo(uid, true);
                        this.setInitialized(true);
                        break;
                    default:
                        break;
                }
                const params = {
                    browser: 'Chrome Extension',
                };
                const deviceId = localStorage.getItem(DEVICE_ID);
                if (deviceId) {
                    params.deviceId = deviceId;
                    await API.updateDevice(params);
                } else {
                    const fpAgent = await FingerprintJS.load();
                    const result = await fpAgent.get();
                    const hashId = FingerprintJS.hashComponents(result);
                    params.deviceUid = hashId;
                    const { data } = await API.registerDevice(params);
                    localStorage.setItem(DEVICE_ID, data.data.device_id);
                }
            } else {
                window.debug.log('auth is null');
                this.setInitialized(true);
                this.userInfoState = UserInfoState.None;
                clearAuthInfo();
            }
        } catch (error) {
            window.debug.log('Error while getting token: ', error);
        }
        this.setInitialized(true);
    };

    setInitialized = value => {
        if (this.state.initialized !== value) {
            this.setState({
                ...this.state,
                initialized: value,
            });
        }
    };

    updateUserInfo = async wait => {
        this.userInfoState = UserInfoState.Updating;
        const initialJob = [];

        initialJob.push(refreshUserInfo(true));

        if (wait) {
            try {
                window.debug.log(await Promise.all(initialJob));
            } catch (error) {
                return redirectToError();
            }
        }

        this.userInfoState = UserInfoState.Updated;
        this.setInitialized(true);
    };

    startUp = async _ => {
        const userKey = safe(window.query, 'userKey');
        const savedKey = localStorage.getItem('__userKey');
        if (userKey && savedKey && decodeURIComponent(userKey) !== savedKey) {
            await signOut();
            localStorage.setItem('__userKey', decodeURIComponent(userKey));
        }
        const uid = safe(store.getState(), 'account/userInfo/uid');
        if (wasRestored() && uid) {
            await this.updateUserInfo(false);
        } else {
            this.userInfoState = UserInfoState.Undefined;
        }
    };

    updateTimeZone = () => {
        let timezone = safe(store.getState(), 'setting/general/timeZone/name') || 'Asia/Tokyo';
        if (!timezone) {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            Actions.setSettingTimeZone(store.dispatch, {
                name: timeZone,
                offset: moment.tz(timeZone).format('Z'),
            });
        }
    };

    componentDidMount() {
        this.updateTimeZone();
    }

    componentWillMount() {
        window.uploadManager = this.uploadManager;
        window.transferManager = this.transferManager;
        initStorage();
        API.authInit(this.handleTokenRefreshed);
        this.startUp();
    }

    componentWillUnmount() {
        finalizeStorage();
    }

    render() {
        const { initialized } = this.state;
        const handleTokenRefreshed = this.handleTokenRefreshed;
        const renderPage = (item, i) =>
            item.condition ? (
                <ConditionalRoute
                    key={i}
                    path={item.path}
                    exact={item.exact !== false}
                    condition={item.condition}
                    redirectTo={item.redirectTo}
                    component={props => item.routes({...props, handleTokenRefreshed})}
                />
            ) : (
                <Route key={i} path={item.path} exact={item.exact !== false} component={props => item.routes({...props, handleTokenRefreshed})} />
            );

        if (initialized) {
            return (
                <div className="App">
                    <OperationProcessor history={this.props.history}>
                        {ref => (
                            <OperationProcessorContext.Provider value={ref}>
                                <Suspense fallback={<div />}>
                                    <Switch>
                                        {Object.values(Pages).map(renderPage)}
                                        {Object.values(AdditionalPages).map(renderPage)}
                                    </Switch>
                                </Suspense>
                            </OperationProcessorContext.Provider>
                        )}
                    </OperationProcessor>
                    {/* <NotificationSystem ref={ref => window.notificationSystem = ref } style={ToastStyle} /> */}
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        width: '100vw',
                        height: '100vh',
                    }}
                >
                    <GlobalIndicator />
                </div>
            );
        }
    }
}

export default App;
