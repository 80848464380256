import * as Colors from '@bit/estmob.frontier-components.colors';

const TextWeight = {
    Light: 300,
    Regular: 400,
    Normal: 400,
    Medium: 500,
    Bold: 700,
};

const TextWeightStyle = {
    Light: {
        fontWeight: TextWeight.Light,
    },
    Regular: {
        fontWeight: TextWeight.Regular,
    },
    Normal: {
        fontWeight: TextWeight.Normal,
    },
    Medium: {
        fontWeight: TextWeight.Medium,
    },
    Bold: {
        fontWeight: TextWeight.Bold,
    },
};

const TextColor = {
    gray: Colors.GRAY6,
};

const TextColorStyles = {
    '&.grayed': {
        color: TextColor.gray,
    },
};

const TextWeightStyles = {
    '&.bold': TextWeightStyle.Bold,
};

const TextTransformStyles = {
    '&.no-transform': {
        textTransform: 'none',
    },
    '&.uppercased': {
        textTransform: 'uppercase',
    },
    '&.lowercased': {
        textTransform: 'lowercase',
    },
};

const AdditionalStyle = {
    ...TextColorStyles,
    ...TextWeightStyles,
    ...TextTransformStyles,
};

const TextStyles = {
    h1: {
        fontWeight: TextWeight.Bold,
        fontSize: '80px',
        character: '-2px',
        line: '80px(1)',

        ...AdditionalStyle,
    },
    h2: {
        fontWeight: TextWeight.Bold,
        fontSize: '36px',
        character: '-1px',
        line: '48px(1.3)',

        ...AdditionalStyle,
    },
    h3: {
        fontWeight: TextWeight.Bold,
        fontSize: '24px',
        character: '-0.4px',
        line: '32px(1.3)',

        ...AdditionalStyle,
    },
    h4: {
        fontWeight: TextWeight.Bold,
        fontSize: '20px',
        character: '-0.5px',
        line: '28px(1.4)',

        ...AdditionalStyle,
    },
    subtitle1: {
        fontWeight: TextWeight.Regular,
        fontSize: '16px',
        character: '-0.5px',
        line: '24px(1.5)',

        ...AdditionalStyle,
    },
    body1: {
        fontWeight: TextWeight.Regular,
        fontSize: '14px',
        character: '-0.4px',
        line: '20px(1.4)',

        ...AdditionalStyle,
    },
    body2: {
        fontWeight: TextWeight.Regular,
        fontSize: '12px',
        character: '0px',
        line: '16px(1.3)',

        ...AdditionalStyle,
    },
};

const ButtonBase = {
    fontWeight: TextWeight.Regular,
    letterSpacing: '-0.5px',

    boxShadow: 'none',
    textTransform: 'none',
    padding: '12px 24px',

    border: 'none', //'1px solid',
    color: '#fff',
    backgroundColor: Colors.INDIGO6,
    // borderColor: Colors.INDIGO6,

    '&:hover': {
        backgroundColor: Colors.INDIGO6,
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#4263eb',
    },
    '&:focus': {},
    '&:disabled': {
        color: '#ced4da',
        backgroundColor: '#f1f3f5',
    },
};

const TextButtonBase = {
    ...TextStyles.body1,
    textDecoration: 'underline',

    boxShadow: 'none',
    textTransform: 'none',
    padding: '0px 0px',

    border: 'none',
    color: '#4263eb',

    '&:hover': {
        color: '#5c7cfa',
    },
    '&:active': {
        color: '#5c7cfa',
    },
    '&:focus': {},
    '&:disabled': {
        color: '#ced4da',
    },
};

const ButtonStyles = {
    default: {
        ...ButtonBase,

        fontSize: '16px',
        lineHeight: '24px',
    },
    small: {
        ...ButtonBase,

        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '-0.4px',
        padding: '8px 16px',
    },
    large: {
        ...ButtonBase,

        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '28px',
        padding: '10px 24px',
    },
    border: {
        border: '1px solid',
        color: Colors.GRAY7,
        backgroundColor: '#fff',
        borderColor: 'rgba(0, 20, 61, 0.08)',

        '&:hover': {
            backgroundColor: '#fff',
        },
        '&:active': {
            backgroundColor: Colors.GRAY0,
        },
    },
    textPrimary: {
        ...TextButtonBase,
    },
    textGrey1: {
        ...TextButtonBase,
        ...TextStyles.body2,
        fontWeight: TextWeight.Bold,
        color: Colors.GRAY6,
        '&:hover': {
            color: Colors.GRAY5,
        },
        '&:active': {
            color: Colors.GRAY7,
        },
    },
    textGrey2: {
        ...TextButtonBase,
        ...TextStyles.body2,
        fontWeight: TextWeight.Bold,
        color: '#ced4da',
        '&:hover': {
            color: '#dee2e6',
        },
        '&:active': {
            color: Colors.GRAY5,
        },
    },
};

const NoSelect = {
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
};

const centerInParent = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
};

const iconDark = (isActive, boldWhenActive = false) => ({
    color: isActive ? Colors.INDIGO6 : Colors.GRAY7,
    ...(boldWhenActive && isActive ? TextWeightStyle.Bold : null),
});

const iconLight = (isActive, boldWhenActive = false) => ({
    color: isActive ? Colors.INDIGO6 : Colors.GRAY6,
    ...(boldWhenActive && isActive ? TextWeightStyle.Bold : null),
});

export default {
    TextWeight,
    TextWeightStyle,
    TextStyles,
    TextColor,
    ButtonStyles,
    NoSelect,
    centerInParent,
    iconLight,
    iconDark,
};
