import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Path from 'path';
import Operations from './operations';
import API from '../api';
import uuidv4 from 'uuidv4';
import { FileTypes, Errors, BLACK_LIST_FILES, getAccessLevel } from '../constants';
import { safe, getAllFilesFromSelection } from '../utils';
import NotifyType from './notifyType';
import { withTranslation } from 'react-i18next';
import Actions from '../redux/actions';
// import * as Ga from '../ga';

const HIDDEN_DOWNLOAD_FRAME_ID = uuidv4();

class OperationProcessor extends React.PureComponent {
    startListeners = {};
    finishListeners = {};

    addStartListener = (namespace, listener) => {
        if (!this.startListeners.hasOwnProperty(namespace)) {
            this.startListeners[namespace] = [];
        }
        this.startListeners[namespace].push(listener);
    };

    removeStartListener = (namespace, listener) => {
        if (this.startListeners.hasOwnProperty(namespace)) {
            this.startListeners[namespace] = this.startListeners[namespace].filter(item => item !== listener);
        }
    };

    addFinishListener = (namespace, listener) => {
        if (!this.finishListeners.hasOwnProperty(namespace)) {
            this.finishListeners[namespace] = [];
        }
        this.finishListeners[namespace].push(listener);
    };

    removeFinishListener = (namespace, listener) => {
        if (this.finishListeners.hasOwnProperty(namespace)) {
            this.finishListeners[namespace] = this.finishListeners[namespace].filter(item => item !== listener);
        }
    };

    _processOperation = namespace => async (op, data, task) => {
        this._handleStart(namespace, op, data);

        let savedResult = null;
        let savedError = null;
        try {
            savedResult = API.managedResolve(null, await task());
        } catch (error) {
            savedError = error;
        }

        this._handleFinish(namespace, op, data, savedResult, savedError);

        return savedResult;
    };

    _handleStart = (namespace, op, data) => {
        window.debug.info('[Operation] Start', op, data);
        window.debug.table(op);

        if (this.startListeners[namespace]) {
            this.startListeners[namespace].forEach(item => item(op, data));
        }
    };

    _handleFinish = (namespace, op, data, result, error) => {
        window.debug.info('[Operation] Finish', op, data, result, error);
        window.debug.table(op);

        if (this.finishListeners[namespace]) {
            this.finishListeners[namespace].forEach(item => item(op, data, result, error));
        }

        const notify = safe(op, 'notification/notify');
        if (notify !== NotifyType.NONE) {
            const notification = window.notificationSystem;
            const showSuccess = message => {
                const flag = safe(op, 'notification/success');
                if (flag !== 'skip') {
                    notification.addNotification({
                        message,
                        level: 'success',
                        autoDismiss: flag === 'keep' ? 0 : 4,
                    });
                }
            };
            const showError = message => {
                const flag = safe(op, 'notification/fail');
                if (flag !== 'skip') {
                    notification.addNotification({
                        message,
                        level: 'error',
                        autoDismiss: safe(op, 'notification/fail') === 'keep' ? 0 : 4,
                    });
                }
            };
            const { t = a => a } = this.props;
            const res = result || error;

            if (API.isSucceeded(res)) {
                if (notify !== NotifyType.FAIL) {
                    showSuccess(t('SUCCESS'));
                }
            } else {
                if (notify !== NotifyType.SUCCESS) {
                    showError(Errors.translateErrors(op, error, t));
                }
            }
        }
    };

    cancelUpload = () => {
        window.uploadManager && window.uploadManager.cancelUpload();
    };

    processOperation = namespace => async (op, data) => {
        const state = window.store.getState();
        const hostId = state.storage[namespace].hostId || state.account.userInfo.uid;
        const currentPath = state.storage[namespace].currentPath;
        const keyPath = state.storage[namespace].keyPath || '';
        const accessLevel = getAccessLevel(state.storage[namespace].accessLevel);

        window.debug.log('operation:', op.id);
        window.debug.table(op);
        window.debug.table(data);

        if (accessLevel && !accessLevel.isValidOperation(op.id)) {
            return;
        }

        switch (op.id) {
            case Operations.UPLOAD.id:
                {
                    this.uploadCanceled = false;
                    const files = Object.values(data).filter(item => !BLACK_LIST_FILES.has(item.name));
                    window.debug.log(files);
                    if (currentPath && files) {
                        try {
                            this.props.onSetActive(1);
                            await this._processOperation(namespace)(op, data, async () => {
                                return await window.uploadManager.pushUpload(files, currentPath, hostId, keyPath);
                                // const result = await API.upload(files, currentPath, hostId, keyPath, (tp, cp, tc, cc) => {
                                //     window.debug.log(tp, cp, tc, cc)
                                //     this.updateProgress(tp, cp, tc, cc)
                                //     return this.uploadCanceled
                                // })
                                // window.debug.log(result)
                                // return result
                            });
                            window.debug.log('done uploading.');
                        } catch (error) {
                            window.debug.log('OperationHandler Error: ', error);

                            switch (namespace) {
                                case 'mydrive':
                                    // Ga.MANAGER.sendEvent(Ga.CATEGORY.CLOUD_MYDRIVE, Ga.ACTION.UPLOAD, Ga.LABEL.MYDRIVE_UPLOAD_FILE_FAIL);
                                    break;
                                case 'sharing':
                                    // Ga.MANAGER.sendEvent(Ga.CATEGORY.CLOUD_SHARED_WITH_ME, Ga.ACTION.UPLOAD, Ga.LABEL.SHARED_WITH_UPLOAD_FILE_FAIL);
                                    break;
                                default:
                                    break;
                            }

                            throw API.managedReject(null, error);
                        } finally {
                            this.props.onSetActive(-1);
                        }
                    }
                }
                break;
            case Operations.UPLOAD_FOLDER.id:
                {
                    this.uploadCanceled = false;
                    const files = Object.values(data).filter(item => !BLACK_LIST_FILES.has(item.name));
                    if (currentPath && files) {
                        try {
                            this.props.onSetActive(1);
                            await this._processOperation(namespace)(op, data, async () => {
                                return await window.uploadManager.pushUpload(files, currentPath, hostId, keyPath);
                            });
                            window.debug.log('done uploading.');
                        } catch (error) {
                            window.debug.log('OperationHandler Error: ', error);

                            switch (namespace) {
                                case 'mydrive':
                                    // Ga.MANAGER.sendEvent(Ga.CATEGORY.CLOUD_MYDRIVE, Ga.ACTION.UPLOAD, Ga.LABEL.MYDRIVE_UPLOAD_FOLDER_FAIL);
                                    break;
                                case 'sharing':
                                    // Ga.MANAGER.sendEvent(Ga.CATEGORY.CLOUD_SHARED_WITH_ME, Ga.ACTION.UPLOAD, Ga.LABEL.SHARED_WITH_UPLOAD_FOLDER_FAIL);
                                    break;
                                default:
                                    break;
                            }

                            throw API.managedReject(null, error);
                        } finally {
                            this.props.onSetActive(-1);
                        }
                    }
                }
                break;
            case Operations.DOWNLOAD.id:
                if (data && data.length > 0) {
                    if (data.length === 1 && data[0].type !== FileTypes.FOLDER) {
                        try {
                            await this._processOperation(namespace)(op, data, async () => {
                                const item = data[0];
                                window.debug.log(currentPath, item, keyPath);
                                const result = await API.getDownloadLink(hostId, currentPath, [item.path]);
                                const resultData = API.extractData(result);
                                if (resultData) {
                                    const { url } = resultData;

                                    let iframe = document.getElementById(HIDDEN_DOWNLOAD_FRAME_ID);
                                    if (!iframe) {
                                        iframe = document.createElement('iframe');
                                        iframe.id = HIDDEN_DOWNLOAD_FRAME_ID;
                                        iframe.style.display = 'none';
                                        document.body.appendChild(iframe);
                                    }
                                    iframe.src = url;
                                }
                                return result;
                            });
                        } catch (error) {
                            window.debug.log('OperationHandler Error: ', error);
                            throw API.managedReject(null, error);
                        }
                    } else {
                        try {
                            await this._processOperation(namespace)(op, data, async () => {
                                const result = await API.getDownloadLink(hostId, currentPath, data.map(item => item.path));
                                const resultData = API.extractData(result);
                                if (resultData) {
                                    const { url } = resultData;

                                    let iframe = document.getElementById(HIDDEN_DOWNLOAD_FRAME_ID);
                                    if (!iframe) {
                                        iframe = document.createElement('iframe');
                                        iframe.id = HIDDEN_DOWNLOAD_FRAME_ID;
                                        iframe.style.display = 'none';
                                        document.body.appendChild(iframe);
                                    }
                                    iframe.src = url;
                                }
                                return result;
                            });
                        } catch (error) {
                            window.debug.log('OperationHandler Error: ', error);
                            throw API.managedReject(null, error);
                        }
                    }
                }
                break;
            case Operations.DELETE.id:
                await this._processOperation(namespace)(op, data, async () => {});
                break;
            case Operations.DELETE_ACTION.id:
                await this._processOperation(namespace)(op, data, async () => {
                    try {
                        return await API.deleteFile(hostId, data.map(item => Path.join(item.path, item.type === FileTypes.FOLDER ? '/' : '')));
                    } catch (error) {
                        window.debug.log('OperationHandler Error: ', error);
                        throw API.managedReject(null, error);
                    }
                });
                break;
            case Operations.NEW_FOLDER.id:
                //                await window.sendyEvents.publish(EVENT_REFRESH, '111')
                await this._processOperation(namespace)(op, data, () => {});
                break;
            case Operations.NEW_FOLDER_ACTION.id:
                await this._processOperation(namespace)(op, data, async () => {
                    window.debug.log(currentPath, data);
                    try {
                        return await API.createFolder(hostId, currentPath, data);
                    } catch (error) {
                        window.debug.log('OperationHandler Error: ', error);
                        switch (namespace) {
                            case 'mydrive':
                                // Ga.MANAGER.sendEvent(Ga.CATEGORY.CLOUD_MYDRIVE, Ga.ACTION.CREATE, Ga.LABEL.MYDRIVE_FOLDER_FAIL);
                                break;
                            case 'sharing':
                                // Ga.MANAGER.sendEvent(Ga.CATEGORY.CLOUD_SHARED_WITH_ME, Ga.ACTION.CREATE, Ga.LABEL.SHARED_WITH_CREATE_FOLDER_FAIL);
                                break;
                            default:
                                break;
                        }
                        throw API.managedReject(null, error);
                    }
                });
                break;
            case Operations.RENAME.id:
                await this._processOperation(namespace)(op, data, () => {});
                break;
            case Operations.RENAME_ACTION.id:
                if (data.name && Path.basename(data.file.path) !== data.name) {
                    await this._processOperation(namespace)(op, data, async () => {
                        window.debug.log(data);
                        try {
                            return await API.renameFile(hostId, data.file.path, data.name);
                        } catch (error) {
                            window.debug.log('OperationHandler Error: ', error);
                            throw API.managedReject(null, error);
                        }
                    });
                }
                break;
            case Operations.COPY.id:
                await this._processOperation(namespace)(op, data, () => {});
                break;
            case Operations.COPY_ACTION.id:
                await this._processOperation(namespace)(op, data, async () => {
                    window.debug.log(data);
                    try {
                        return await API.copyFile(hostId, data.fileDataList, data.to, data.lang);
                    } catch (error) {
                        window.debug.log('OperationHandler Error: ', error);
                        throw API.managedReject(null, error);
                    }
                });
                break;
            case Operations.MOVE.id:
                await this._processOperation(namespace)(op, data, () => {
                    const sharedCount = data.filter(item => item.shared !== 'none').length;

                    if (sharedCount > 0) {
                        throw API.managedReject(null, null, { code: Errors.MOVE_SHARE_FOLDER });
                    }
                });
                break;
            case Operations.MOVE_ACTION.id:
                await this._processOperation(namespace)(op, data, async () => {
                    window.debug.log(data);
                    try {
                        return await API.moveFile(hostId, data.fileDataList, data.to, data.lang);
                    } catch (error) {
                        window.debug.log('OperationHandler Error: ', error);
                        throw API.managedReject(null, error);
                    }
                });
                break;
            case Operations.SHARE.id:
                await this._processOperation(namespace)(op, data, async () => {
                    window.debug.log(data);
                    return await API.checkFolderShareable(data[0].path || data);
                });
                break;
            case Operations.SHARE_MEMBER_ACTION.id:
                await this._processOperation(namespace)(op, data, async () => {
                    window.debug.log(data);
                    try {
                        return await API.createShareFolder(data.path, data.message, data.users);
                    } catch (error) {
                        window.debug.log('OperationHandler Error: ', error);
                        // Ga.MANAGER.sendEvent(Ga.CATEGORY.CLOUD_MYDRIVE, Ga.ACTION.SHARE, Ga.LABEL.MYDRIVE_SHARE_MEMBER_SHARE_FAIL);
                        throw API.managedReject(null, error);
                    }
                });
                break;
            case Operations.SHARE_INVITE_ACTION.id:
                await this._processOperation(namespace)(op, data, async () => {
                    window.debug.log(data);
                    try {
                        return await API.createInviteFolder(data.path, data.level, data.password, data.expiration);
                    } catch (error) {
                        window.debug.log('OperationHandler Error: ', error);
                        // Ga.MANAGER.sendEvent(Ga.CATEGORY.CLOUD_MYDRIVE, Ga.ACTION.SHARE, Ga.LABEL.MYDRIVE_SHARE_INVITE_CREATE_FAIL);
                        throw API.managedReject(null, error);
                    }
                });
                break;
            case Operations.UNSHARE_ACTION.id:
                await this._processOperation(namespace)(op, data, async () => {
                    window.debug.log(data);
                    return await API.unshareLink(data.path, data.type);
                });
                break;
            case Operations.RESTORE.id:
                await this._processOperation(namespace)(op, data, async () => {
                    return await API.restoreFiles(data);
                });
                break;
            case Operations.DELETE_PAMANENTLY.id:
            case Operations.EMPTY_TRASH.id:
                await this._processOperation(namespace)(op, data, async () => {});
                break;
            case Operations.DELETE_PAMANENTLY_ACTION.id:
                await this._processOperation(namespace)(op, data, async () => {
                    try {
                        return await API.deleteFilesPermanently(data);
                    } catch (error) {
                        window.debug.log('OperationHandler Error: ', error);
                        throw API.managedReject(null, error);
                    }
                });
                break;
            case Operations.EMPTY_TRASH_ACTION.id:
                await this._processOperation(namespace)(op, data, async () => {
                    try {
                        return await API.emptyTrash();
                    } catch (error) {
                        window.debug.log('OperationHandler Error: ', error);
                        throw API.managedReject(null, error);
                    }
                });
                break;
            case Operations.ADD_COMMENT.id:
                if (data && data.length > 0) {
                    await this._processOperation(namespace)(op, data, () => {});
                }
                break;
            case Operations.BROWSE_TO.id:
                if (data && data.length > 0) {
                    await this._processOperation(namespace)(op, data, () => {});
                }
                break;
            case Operations.VERSION.id:
                await this._processOperation(namespace)(op, data, () => {});
                break;
            case Operations.VERSION_ACTION.id:
                await this._processOperation(namespace)(op, data, async () => {
                    window.debug.log(data);
                    // return await API.copyFile(hostId, data.fileDataList, data.to, data.lang)
                });
                break;
            case Operations.SEND.id:
                await this._processOperation(namespace)(op, data, async () => {
                    const result = await getAllFilesFromSelection(hostId, currentPath, data);
                    return API.managedResolve(null, result);
                });
                break;
            default:
                await this._processOperation(namespace)(op, data, () => {});
                break;
        }
    };

    render() {
        const { children } = this.props;

        return children(this);
    }
}

const mapDispatchToProps = dispatch => ({
    onSetActive: delta => Actions.setStatusTransferActive(dispatch, delta),
    onSetProgress: (tp, cp, tc, cc) => Actions.setStatusTransferProgress(dispatch, tp, cp, tc, cc),
});

export default compose(
    withTranslation(),
    connect(
        null,
        mapDispatchToProps,
        null,
        { forwardRef: true },
    ),
)(OperationProcessor);
