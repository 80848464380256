import Keys from './keys';
import Setting from './setting';
import Relay from './relay';
export { RelayServer } from './relay';

export default {
    ...Keys,
    ...Setting,
    ...Relay,
};
