import Common from './common';
import Auth from './auth';
import { store } from '../Root';
import { SupportLanguages } from '../constants';
import i18n from '../i18n';
import Actions from '../redux/actions';
import throttle from 'lodash.throttle';
import API from '../api';
import Transfer from './transfer';
import Cloud from './cloud';
import Account from './account';
import S3 from './transfer-s3';

export default Object.assign({}, Common, Auth, Account, S3, {
    transfer: Transfer,
    cloud: Cloud,
});

const getDefaultLanguage = () => {
    const lang = navigator.language || navigator.userLanguage;
    return SupportLanguages.match(lang);
};

export const refreshUserInfo = async force => {
    if (store) {
        const promises = [];
        let language = null;
        promises.push(
            API.getAccountSettings()
                .then(result => {
                    const data = Common.extractData(result);
                    if (data) {
                        if (!data.language) {
                            data.language = getDefaultLanguage().langCode;
                            API.setAccountSettings({ language: data.language });
                        }
                        Actions.updateAccountSetting(store.dispatch, data);
                        //language = SupportLanguages.match(data.language);
                    }
                })
                .catch(error => {
                    window.debug.error('Getting account settings error: ', error);
                }),
        );
        const uid = Common.getId();
        if (uid) {
            promises.push(
                API.cloud
                    .getUserInfo(uid)
                    .then(result => {
                        window.debug.log(result);
                        const data = API.extractData(result);
                        if (data) {
                            Actions.updateCloudSetting(store.dispatch, data);
                        }
                    })
                    .catch(error => {
                        window.debug.error('Getting user settings error:', error);
                    }),
            );
        }
        await Promise.all(promises);

        API.cloud
            .getCapacities(null)
            .then(result => {
                const data = API.extractData(result);
                if (data) {
                    Actions.updateCloudSetting(
                        store.dispatch,
                        {
                            usage: data.total_size,
                        },
                        true,
                    );
                }
            })
            .catch(error => {
                window.debug.log('Getting capacity error:', error);
            });

        if (!language) {
            language = getDefaultLanguage();
        }

        window.debug.log(`Setting current language as ${language.i18nLanguage}`, language.language);

        if (language.i18nLanguage !== i18n.language || force) {
            await i18n.changeLanguage(language.i18nLanguage);
        }

        if (language) {
            localStorage.setItem('__intl', language.langCode);
        }

        return null;
    }

    return null;
};

export const requestRefreshUserInfo = throttle(() => refreshUserInfo(), 1000);

export { RelayServer } from './transfer';
