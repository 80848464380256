import { AUTH_KEY, AUTH_KEY_VALUE, INDEXED_DB, INDEXED_DB_VERSION } from '../constants';

const getObjectStore = async (mode) => {
    if (!window.db) {
        await createIndexedDB();
    }

    return window.db.transaction([INDEXED_DB], mode).objectStore(INDEXED_DB);
}

export const createIndexedDB = () => {
    return new Promise((resolve, reject) => {
        const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
        const request = indexedDB.open(INDEXED_DB, INDEXED_DB_VERSION);
    
        request.onsuccess = (event) => {
            window.db = event.target.result;
            resolve();
        }
    
        request.onerror = (event) => {
            reject();
            //실패 시 사용 불가능 처리
        }
    
        request.onupgradeneeded = (event) => {
            // const objectStore = event.currentTarget.result.createObjectStore(INDEXED_DB, { keyPath: AUTH_KEY });
            event.currentTarget.result.createObjectStore(INDEXED_DB, { keyPath: AUTH_KEY });
            // objectStore.put({test: 'tset', [AUTH_KEY]: 1}, )
        }
    })
}

export const getAuthUserFromIndexedDB = async () => {
    const store = await getObjectStore('readonly');
    return new Promise((resolve, reject) => {
        const request = store.get(AUTH_KEY_VALUE);

        request.onsuccess = (event) => {
            resolve(event.target.result);
        }
    
        request.onerror = (event) => {
            reject(event.target.error);
        }
    })
}

export const setAuthUserInIndexedDB = (data) => {
    return new Promise(async (resolve, reject) => {
        const store = await getObjectStore('readwrite');
        data.storage = 'indexDB';
        const request = store.put({ [AUTH_KEY]: AUTH_KEY_VALUE, ...data });

        request.onsuccess = () => {
            resolve();
        }
    
        request.onerror = (event) => {
            reject(event.target.error);
        }
    })
}

export const clearIndexedDB = async () => {
    const store = await getObjectStore('readwrite');
    return new Promise((resolve, reject) => {
        const request = store.delete(AUTH_KEY_VALUE);

        request.onsuccess = () => {
            resolve();
        }
    
        request.onerror = (event) => {
            reject(event.target.error);
        }
    })
}