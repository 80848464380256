export const HOME = '/extension';
export const SEND = `${HOME}/send`;
export const MAIN = `${HOME}/main`;
export const ERROR = `${HOME}/error`;
export const CLOUD = `${HOME}/cloud`;
export const MY_SENDY = `${HOME}/setting`;
export const SIGN_IN = `${HOME}/signin`;
export const SERVER_ERROR = `${HOME}/unavailable`;
export const RID_REDIRECT = `${HOME}/oauth-callback`;
export const SENT_DETAIL = '/sent/detail/:created_at';

export const PRIVACY_AND_POLICY = {
  japanese: 'https://home.rakuten-drive.com/ja/privacy/',
  english: 'https://home.rakuten-drive.com/privacy-en/',
  korean: 'https://home.rakuten-drive.com/privacy-en/',
};
export const TERMS_OF_SERVICS = {
  japanese: 'https://home.rakuten-drive.com/ja/privacy/',
  english: 'https://home.rakuten-drive.com/privacy-en/',
  korean: 'https://home.rakuten-drive.com/privacy-en/',
};
export const SENDY_URL = 'https://home.rakuten-drive.com/';
export const HELP_URL = 'https://support.rakuten-drive.com/hc/';
