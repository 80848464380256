export const safe = (object, ...args) => {
    let res = object;
    if (args && object) {
        for (const arg of args) {
            for (const path of arg.split(/[\s./]+/).filter(item => item.length > 0)) {
                const val = res[path];
                if (!val) {
                    return undefined;
                }
                res = val;
            }
        }
    }
    return res;
};

export const safeCall = (object, ...args) => param => {
    const func = safe(object, ...args);
    if (func) {
        return func(param);
    }
    return null;
};
