import Actions from '../actions';

export default (state = {}, action) => {
    switch (action.type) {
        case Actions.SET_STATUS_PAGE_INDICATOR: {
            return Object.assign({}, state, {
                pageIndicator: action.set,
            });
        }
        case Actions.SET_STATUS_GLOBAL_INDICATOR: {
            return Object.assign({}, state, {
                globalIndicator: action.set,
            });
        }
        case Actions.SET_STATUS_WORK_INDICATOR: {
            return Object.assign({}, state, {
                workIndicator: action.set,
            });
        }
        case Actions.SET_STATUS_TRANSFER_PROGRESS: {
            return Object.assign({}, state, {
                transferProgress: Object.assign({}, state.transferProgress, {
                    totalProgress: action.tp,
                    progress: action.cp,
                    totalCount: action.tc,
                    count: action.cc,
                }),
            });
        }
        case Actions.SET_STATUS_TRANSFER_ACTIVE: {
            return Object.assign({}, state, {
                transferProgress: {
                    ...state.transferProgress,
                    active: Math.max(0, state.transferProgress.active + action.delta),
                },
            });
        }
        default:
            return state;
    }
};
