import moment from 'moment';
import { safe } from './safe';
import { Routes } from '../constants';
import { store } from '../Root';
import { deepAssign } from './utils';
import API from '../api';

const CLOUD_CACHE = '__extension_cache';
const ACCOUNT_CACHE = '__sendy_cache';
const RESTORE_CACHE = '__extension_restore_cache';

const IGNORED_STORE_KEYS_SAVE = ['status', 'profileCache'];
const IGNORED_STORE_KEYS_LOAD = ['status', 'router', 'profileCache'];

export const NAVIGATION_TYPE = safe(window, 'performance.navigation.type') || (window.self !== window.top ? 100 : null);
console.log('Navigation Type: ', NAVIGATION_TYPE);

let _WAS_RESTORED = false;
export const wasRestored = () => _WAS_RESTORED;

const loadLocalSendyCache = () => {
    const cache = localStorage.getItem(ACCOUNT_CACHE);
    if (cache) {
        const obj = JSON.stringify(cache);
        return {
            account: {
                email: obj.email,
                idToken: obj.idToken,
                image: obj.image,
                name: obj.name,
                uid: obj.uid,
                verified: obj.verified,
            },
        };
    }
    return undefined;
};

const loadLocalStorage = () => {
    const sendyCache = loadLocalSendyCache();
    const serializedState = localStorage.getItem(CLOUD_CACHE) || localStorage.getItem('state');
    return deepAssign({}, sendyCache, JSON.parse(serializedState));
};

const loadSessionStorage = () => {
    try {
        // if (NAVIGATION_TYPE) {
        const restored = JSON.parse(localStorage.getItem(RESTORE_CACHE));
        if (restored && restored.timestamp && restored.router && restored.router.location && restored.router.location.pathname) {
            const blacklist = [Routes.ERROR, Routes.SERVER_ERROR];
            if (!blacklist.some(item => item === restored.router.location.pathname)) {
                const time = new Date(restored.timestamp);
                const diff = moment().diff(time, 'minutes');
                if (diff < 60) {
                    delete restored.timestamp;
                    IGNORED_STORE_KEYS_LOAD.forEach(item => delete restored[item]);
                    _WAS_RESTORED = true;
                    window.debug.log('Session Restored');
                    return restored;
                }
            } else {
                window.debug.log('Session Ignored by data', restored);
            }
        } else {
            window.debug.log('Session Ignored by data', restored);
        }
        // } else {
        //     window.debug.log('Session Ignored by Navigation type');
        // }
    } catch (err) {}
    return null;
};

export const loadState = () => deepAssign({}, loadSessionStorage(), loadLocalStorage());

const saveStoreToLocalStorage = state => {
    try {
        const { view, setting, account } = state;
        const serializedState = JSON.stringify({ view, setting });
        localStorage.setItem(CLOUD_CACHE, serializedState);

        if (safe(account, 'userInfo')) {
            const { email, idToken, image, name, uid, verified } = account.userInfo;
            localStorage.setItem(ACCOUNT_CACHE, JSON.stringify({ email, idToken, image, name, uid, verified }));
        }
    } catch {
        // ignore write errors
    }
};

const saveRestorableCache = state => {
    if (clearRequested || !API.isLoggedIn(state)) {
        localStorage.removeItem(RESTORE_CACHE);
    } else {
        IGNORED_STORE_KEYS_SAVE.forEach(item => delete state[item]);
        localStorage.setItem(
            RESTORE_CACHE,
            JSON.stringify({
                ...state,
                timestamp: new Date(),
            }),
        );
    }
};

const handleUnload = () => {
    ['state', 'unload'].forEach(item => {
        localStorage.removeItem(item);
        sessionStorage.removeItem(item);
    });
    const state = Object.assign({}, store.getState());
    saveRestorableCache(state);
    saveStoreToLocalStorage(state);
};

export const initStorage = () => {
    window.addEventListener('beforeunload', handleUnload);
};

export const finalizeStorage = () => {
    window.removeEventListener('beforeunload', handleUnload);
};

let clearRequested = false;

export const clearSessionStorage = () => {
    clearRequested = true;

    window.debug.log('Session Storage Clearing requested.');
};
