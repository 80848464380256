import React from 'react';

export const OperationProcessorContext = React.createContext(null);

export const withOperation = () => Component =>
    React.forwardRef((props, ref) => (
        <OperationProcessorContext.Consumer>
            {processor => <Component {...props} ref={ref} operationProcessor={processor} processOperation={processor.processOperation} />}
        </OperationProcessorContext.Consumer>
    ));
