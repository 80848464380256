import Common from './common';
import { ServiceInfo } from '../constants';

const SERVICE = 'account';
const VERSION = 1;

const implement = (block) => Common.implement(ServiceInfo.otherApiUrl, SERVICE, VERSION, block);

const getUser = (userId, token) => implement((instance) => instance.get(`users/${userId}`, {
  headers: {
    token,
  },
}));

const getAccountSettings = () => implement((instance) => instance.get('setting'));

const setAccountSettings = ({
  email_customized, gender, birthdate, language,
}) => {
  implement((instance) => instance.post('setting', {
    ...(email_customized ? { email_customized } : null),
    ...(gender ? { gender } : null),
    ...(birthdate ? { birthdate } : null),
    ...(language ? { language } : null),
  }));
};

const registerDevice = ({ deviceUid, browser }) => implement((instance) => instance.post('devices', {
  device_uid: deviceUid,
  browser,
}));

const updateDevice = ({ deviceId, browser }) => implement((instance) => instance.post(`devices/${deviceId}`, {
  browser,
}));

const unregisterDevice = (deviceId) => implement((instance) => instance.delete(`devices/${deviceId}`));

export default {
  getUser,
  getAccountSettings,
  setAccountSettings,
  registerDevice,
  updateDevice,
  unregisterDevice,
};
