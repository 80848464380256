import React, { lazy } from 'react';
import { Redirect } from 'react-router';
import * as Routes from './routes';
import API from '../api';

const MainPage = lazy(() => import('../pages/MainPage'));
const RIDOAuthPage = lazy(() => import('../pages/RIDOAuthPage'));
const Cloud = lazy(() => import('../pages/Cloud'));
const MySendy = lazy(() => import('../pages/MySendy'));
const SignIn = lazy(() => import('../pages/SignIn'));
const NotFound = lazy(() => import('../pages/NotFound'));
const ServerError = lazy(() => import('../pages/ServerError'));

const loginCondition = state => API.isLoggedIn(state);
const logoutCondition = state => !API.isLoggedIn(state);

export const Pages = {
  Main: {
    id: 'Main',
    path: Routes.MAIN,
    condition: loginCondition,
    redirectTo: Routes.SIGN_IN,
    routes: props => <MainPage {...props} />,
  },
  RIDOAuthPage: {
    id: 'RIDOAuthPage',
    path: Routes.RID_REDIRECT,
    condition: logoutCondition,
    redirectTo: Routes.MAIN,
    routes: props => <RIDOAuthPage {...props} />,
  },
  MySendy: {
    id: 'MySendy',
    path: Routes.MY_SENDY,
    condition: loginCondition,
    redirectTo: Routes.SIGN_IN,
    routes: props => <MySendy {...props} />,
  },
  SignIn: {
    id: 'SignIn',
    path: Routes.SIGN_IN,
    condition: logoutCondition,
    redirectTo: Routes.MAIN,
    routes: props => <SignIn {...props} />,
  },

  // test
  Cloud: {
    id: 'Cloud',
    path: Routes.CLOUD,
    condition: loginCondition,
    redirectTo: Routes.SIGN_IN,
    routes: props => <Cloud {...props} />,
  },
};

export const AdditionalPages = {
  Root: {
    id: 'Root',
    path: '/',
    routes: props => <Redirect to={Pages.Main.path} />,
  },
  Home: {
    id: 'Home',
    path: Routes.HOME,
    routes: props => <Redirect to={Pages.Main.path} />,
  },
  NotFound: {
    id: 'Error',
    path: Routes.ERROR,
    routes: props => <NotFound {...props} />,
  },
  ServerError: {
    id: 'ServerError',
    path: Routes.SERVER_ERROR,
    routes: props => <ServerError {...props} />,
  },
  Default: {
    id: 'Default',
    routes: props => <NotFound {...props} />,
  },
};
