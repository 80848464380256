import { Errors, FileTypes, ServiceInfo } from '../../constants';

import Common from '../common';
import Path from 'path';

const SERVICE = 'file';

const implement = (block, v = 1) => Common.implement(ServiceInfo.cloudApiUrl, SERVICE, v, block);

const convertFileData = data => {
    if (Array.isArray(data)) {
        return data.map(item => convertFileData(item));
    } else {
        return {
            name: Path.basename(data.Path),
            path: data.Path,
            size: data.Size,
            type: data.IsFolder ? FileTypes.FOLDER : FileTypes.NORMAL,
            shared: data.IsShare,
            modifiedDate: new Date(data.LastModified).getTime() / 1000,
            thumbnail: data.Thumbnail,
            versionId: data.VersionID,
            modifier: '',
            hasChildFolder: data.HasChildFolder,
        };
    }
};

const getCapacities = hostId =>
    implement(instance =>
        instance.post('capacities', {
            host_id: hostId || Common.getId(),
        }),
    );

const getFiles = (hostId, pathToGet, param = {}) =>
    implement(instance =>
        instance
            .post('files', {
                host_id: hostId || Common.getId(),
                path: Common.preprocessFolderPath(pathToGet),
                ...param,
            })
            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        throw Common.managedReject(null, error, { code: Errors.INVALID });
                    case 401:
                        throw error;
                    case 403:
                        if (error.response.data && error.response.data.error) {
                            switch (error.response.data.error) {
                                case 'SENDY_ERR_PASSWORD_NOT_MATCH':
                                    throw Common.managedReject(null, error, { code: Errors.INVALID_PARAM });
                                case 'SENDY_ERR_LINK_EXPIRED':
                                    throw Common.managedReject(null, error, { code: Errors.EXPIRED });
                                case 'SENDY_ERR_UNINVITED_USER':
                                    throw Common.managedReject(null, error, { code: Errors.FORBIDDEN });
                                default:
                                    break;
                            }
                        }
                        break;
                    default:
                        throw Common.managedReject(null, error, { code: Errors.NOT_FOUND });
                }
            }),
    );

const getFile = (hostId, pathToGet, param = {}) =>
    implement(instance =>
        instance.post('file', {
            host_id: hostId || Common.getId(),
            path: pathToGet,
            ...param,
        }),
    );

const getFilesToDownload = (hostId, pathToGet, folders, param) =>
    implement(instance =>
        instance.post('files/full', {
            path: Common.preprocessFolderPath(pathToGet),
            host_id: hostId,
            file: folders.map(item => ({
                path: item,
            })),
            ...param,
        }),
    );

const getFolders = (hostId, pathToGet, param = {}) => getFiles(hostId, pathToGet, Object.assign(param, { filter: 'folder' }));

// const getUploadLink = async (pathToUpload, param = {}) => {
//     const targetPath = `${Path.join('filelink', pathToUpload, '/')}`
//     const params = {
//         ...param,
//         path: pathToUpload,
//     }

//     return Common.getInstance().post(targetPath, params)
// }

// const getDownloadLink = async (pathToDownload, param = {}) => {
//     const targetPath = `${path.join('filelink', pathToDownload, '/')}`

//     return Common.getInstance().get(targetPath)
// }

const deleteFile = (hostId, paths, trash = true) =>
    implement(async instance => {
        const response = await instance.delete('files', {
            data: {
                host_id: hostId || Common.getId(),
                file: paths.map(item => ({
                    path: item,
                })),
                trash: trash,
            },
        });
        const data = Common.extractData(response);
        if (data && data.key) {
            await checkUntil(instance, data.key);
            window.debug.log(response);
            return response;
        }
        window.debug.log(response);
        return response;
    }, 2);

const renameFile = (hostId, path, name) =>
    implement(async instance => {
        const response = await instance.put('files/rename', {
            host_id: hostId || Common.getId(),
            name: name,
            path: path,
        });
        const data = Common.extractData(response);
        if (data && data.key) {
            await checkUntil(instance, data.key);
            window.debug.log(response);
            return response;
        }
        window.debug.log(response);
        return response;
    }, 2);

const createFolder = (hostId, path, name) =>
    implement(instance =>
        instance
            .post(`files/create`, {
                host_id: hostId || Common.getId(),
                name: name,
                path: Common.preprocessFolderPath(path),
            })
            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        switch (error.response.data ? error.response.data.error : null) {
                            case 'SENDY_ERR_FILE_NO_FOLDER':
                                throw Common.managedReject(null, error, { code: Errors.WRONG_PATH });
                            case 'SENDY_ERR_FILE_NO_SUCH_KEY':
                                throw Common.managedReject(null, error, { code: Errors.NOT_FOUND });
                            case 'SENDY_ERR_FILE_ALREADY_EXIST_FILE_NAME':
                                throw Common.managedReject(null, error, { code: Errors.INVALID });
                            case 'SENDY_ERR_FILE_LONG_KEY':
                                throw Common.managedReject(null, error, { code: Errors.TOO_LONG_NAME });
                            default:
                                throw Common.managedReject(null, error, { code: Errors.UNKNOWN });
                        }
                    case 401:
                        throw error;
                    case 404:
                        throw Common.managedReject(null, error, { code: Errors.CREATE_FOLDER_NOT_FOUND });
                    default:
                        throw Common.managedReject(null, error, { code: Errors.UNKNOWN });
                }
            }),
    );

const checkUntil = (instance, id) =>
    new Promise((resolve, reject) => {
        const task = setInterval(async () => {
            try {
                const result = await instance.post('files/check', {
                    key: id,
                });
                const data = Common.extractData(result);
                window.debug.log(result, data);
                switch (data.state) {
                    case 'complete':
                        clearInterval(task);
                        Common.managedResolve(resolve, result);
                        break;
                    case 'error':
                        clearInterval(task);
                        Common.managedReject(reject, result);
                        break;
                    default:
                        break;
                }
            } catch (error) {
                clearInterval(task);
                const data = error.response ? error.response.data : null;
                //window.debug.log(error.response)

                if (data) {
                    switch (data.message) {
                        case 'SEDNY_ERR_EXCEEDED_FOLDER_MAX_STORAGE':
                            Common.managedReject(reject, error, { code: Errors.STORAGE_LIMIT_EXCEEDED });
                            break;
                        case 'SENDY_ERR_FILE_NO_FOLDER':
                            Common.managedReject(reject, error, { code: Errors.WRONG_PATH });
                            break;
                        case 'SENDY_ERR_FILE_NO_SUCH_KEY':
                            Common.managedReject(reject, error, { code: Errors.NOT_FOUND });
                            break;
                        case 'SENDY_ERR_FILE_WRONG_PATH':
                            Common.managedReject(reject, error, { code: Errors.WRONG_PATH });
                            break;
                        case 'SENDY_ERR_FILE_ALREADY_EXIST_FILE_NAME':
                            Common.managedReject(reject, error, { code: Errors.ALREADY_EXISTS });
                            break;
                        case 'SENDY_ERR_FILE_LONG_KEY':
                            Common.managedReject(reject, error, { code: Errors.TOO_LONG_NAME });
                            break;
                        default:
                            Common.managedReject(reject, error);
                            break;
                    }
                } else {
                    Common.managedReject(reject, error);
                }
            }
        }, 500);
    });

const copyFile = (hostId, from, to, lang) =>
    implement(async instance => {
        const response = await instance.post('files/copy', {
            host_id: hostId || Common.getId(),
            file: from.map(item => ({
                path: item,
            })),
            to_path: Common.preprocessFolderPath(to),
            language: lang,
        });
        const data = Common.extractData(response);
        if (data && data.key) {
            await checkUntil(instance, data.key);
            window.debug.log(response);
            return response;
        }
        window.debug.log(response);
        return response;
    }, 2);

const moveFile = (hostId, from, to, lang) =>
    implement(async instance => {
        const response = await instance.put('files/move', {
            host_id: hostId || Common.getId(),
            file: from.map(item => ({
                path: item,
            })),
            to_path: Common.preprocessFolderPath(to),
            language: lang,
        });
        const data = Common.extractData(response);
        if (data && data.key) {
            await checkUntil(instance, data.key);
            window.debug.log(response);
            return response;
        }
        window.debug.log(response);
        return response;
    }, 2);

const checkUpload = async (hostId, path, files) =>
    implement(instance =>
        instance.post('check/upload', {
            host_id: hostId || Common.getId(),
            file: files.map(item => ({
                path: item.path,
                size: item.size,
            })),
            path: Common.preprocessFolderPath(path),
        }),
    );

const getDownloadLink = (hostId, currentPath, paths) =>
    implement(instance =>
        instance.post('filelink/download', {
            host_id: hostId || Common.getId(),
            path: Common.preprocessFolderPath(currentPath),
            file: paths.map(item => ({ path: item })),
        }),
    );

export default {
    getCapacities,
    getFiles,
    getFile,
    getFolders,
    getFilesToDownload,
    createFolder,
    // getUploadLink,
    // getDownloadLink,
    deleteFile,
    renameFile,

    copyFile,
    moveFile,

    checkUpload,
    getDownloadLink,

    convertFileData,
    checkUntil,
};
// http://forest-test.sendycloud.com/cloud/service/file/v1/files/
// http://forest-test.sendycloud.com/cloud/service/file/v1/files 404 (Not Found)
