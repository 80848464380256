/*eslint no-unused-vars: "off"*/

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { IS_DEV } from './environment';
import moment from 'moment'
import momentja from 'moment/locale/ja'
import momentko from 'moment/locale/ko'

// import KO from './assets/locales/ko/translation.json'

const replaceEscape = value => {
    for (const key of Object.keys(value)) {
        value[key] = value[key].replace(/\\n/gi, '\n')
    }
    return value
}

const resources = {
    en: {
        translation: replaceEscape(require('./assets/locales/en-US.json'))
    },
    ja: {
        translation: replaceEscape(require('./assets/locales/ja-JP.json'))
    },
}

if (IS_DEV) {
    resources.ko = {
        translation: require('./assets/locales/ko-KR.json')
    }
}

i18n
//   // load translation using xhr -> see /public/locales
//   // learn more: https://github.com/i18next/i18next-xhr-backend
//   .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: 'en',
        debug: IS_DEV,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

i18n.on('languageChanged', lng => {
    moment.locale(lng)
})

export default i18n;