import { PropTypes } from 'prop-types';
import { safe } from '../utils';
import { store } from '../Root';

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};

export const drawerWidth = 240;
export const sideBarWidth = 240;

export const CHALLENGE_EMAIL_VERIFY = 'challenge-verify-email';
export const CHALLENGE_FILL_EXTRA_INFO = 'challenge-fill-out-extra-info';
export const CHALLENGE_SHARE_FOLDER = 'challenge-create-share-folder';
export const CHALLENGE_TRANSFER_VIA_SENDY = 'challenge-transfer-via-sendy';

export const INVITATION_REWARD_SIZE = 1 * 1024 * 1024 * 1024;

export const DOMAIN = 'cloud.sendycloud.com';
export const BLACK_LIST_FILES = new Set(['.DS_Store']);

export const GLOBAL_SCROLL_BROADCAST_NAME = 'scroll:global';
export const INFINITE_SCROLLER_DEBOUNCE_TIME = 100;
export const INFINITE_SCROLLER_AMOUNT = 100;

export const PAGE_COUNT = 10;

export const getMaxStorageSize = () => {
  const state = store.getState();
  if (state) {
    const space = safe(state, 'account.settings.cloud.settings.space') || 0;
    const maxSpace = safe(state, 'account.settings.cloud.plan_info.max_space') || 0;

    return maxSpace.plan + 7 * 1024 * 1024 * 1024 + space.bonus + space.invitation; // + maxSpace.challenge
  }
  return 0;
};

export const getAvailableStorageSize = () => {
  const state = store.getState();
  const space = safe(state, 'account.settings.cloud.settings.space');

  return space ? Object.values(space).reduce((value, item) => value + item, 0) : 0;
};

export const FileDataType = {
  name: PropTypes.string,
  size: PropTypes.number,
};

export const AttachTarget = {
  gmail: 'gmail',
  slack: 'slack',
  charwork: 'chatwork',
};

export const EMAIL_CHECKER = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const INDEXED_DB = 'sendyDataBase';
export const INDEXED_DB_VERSION = 1;
export const AUTH_KEY = 'sendy';
export const AUTH_KEY_VALUE = 'sendy:authUser';
export const RID_AUTH_CODE = 'ridAuthCode';
