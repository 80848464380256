import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import account from './account';
import status from './status';
import profileCache from './profile-cache';
import sent from './sent';
import setting from './setting';
import data from './data';

export default history =>
    combineReducers({
        router: connectRouter(history),
        account,
        status,
        profileCache,
        sent,
        setting,
        data,
    });
