import Actions from '../actions';

export default (state = {}, action) => {
    switch (action.type) {
        case Actions.ACTION_UPDATE_SENT_KEYS:
            const links = action.append === true ? Object.assign({}, state.links) : {};
            action.links
                //                .filter(item => item.progress === 'uploaded')
                .forEach(item => {
                    links[item.key] = item;
                    // links[item.key + '1'] = item;
                    // links[item.key + '2'] = item;
                    // links[item.key + '3'] = item;
                    // links[item.key + '4'] = item;
                    // links[item.key + '5'] = item;
                    // links[item.key + '6'] = item;
                    // links[item.key + '7'] = item;
                    // links[item.key + '8'] = item;
                    // links[item.key + '9'] = item;
                    // links[item.key + '0'] = item;
                });
            return Object.assign({}, state, {
                links,
                lastKey: action.last_key,
            });
        default:
            return state;
    }
};
