export const SET_STATUS_PAGE_INDICATOR = 'SET_STATUS_PAGE_INDICATOR';
export const SET_STATUS_WORK_INDICATOR = 'SET_STATUS_WORK_INDICATOR';
export const SET_STATUS_GLOBAL_INDICATOR = 'SET_STATUS_GLOBAL_INDICATOR';
export const SET_STATUS_TRANSFER_ACTIVE = 'SET_STATUS_TRANSFER_ACTIVE';
export const SET_STATUS_TRANSFER_PROGRESS = 'SET_STATUS_TRANSFER_PROGRESS';

export const setStatusGlobalIndicator = (dispatch, set) => {
    dispatch({
        type: SET_STATUS_GLOBAL_INDICATOR,
        set,
    });
};

export const setStatusPageIndicator = (dispatch, set) => {
    dispatch({
        type: SET_STATUS_PAGE_INDICATOR,
        set,
    });
};

export const setStatusWorkIndicator = (dispatch, set) => {
    dispatch({
        type: SET_STATUS_WORK_INDICATOR,
        set,
    });
};

export const setStatusTransferActive = (dispatch, delta) => {
    dispatch({
        type: SET_STATUS_TRANSFER_ACTIVE,
        delta,
    });
};

export const setStatusTransferProgress = (dispatch, tp, cp, tc, cc) => {
    dispatch({
        type: SET_STATUS_TRANSFER_PROGRESS,
        tp,
        cp,
        tc,
        cc,
    });
};
