import Actions from '../actions';

export default (state = {}, action) => {
    switch (action.type) {
        case Actions.UPDATE_FILE_LIST:
            return Object.assign({}, state, {
                files: Object.assign({}, action.merge === true ? state.files : null, state.files),
            });
        default:
            return state;
    }
};
