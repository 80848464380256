import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';

const ConditionalRoute = ({ component: Component, redirectTo, condition, conditionValue, ...rest }) => {
    return <Route {...rest} render={props => (conditionValue ? <Component {...props} /> : <Redirect to={redirectTo} />)} />;
};

ConditionalRoute.propTypes = {
    condition: PropTypes.func.isRequired,
    redirectTo: PropTypes.string.isRequired,
    conditionValue: PropTypes.bool,
};

ConditionalRoute.defaultProps = {
    conditionValue: false,
};

const mapStateToProps = (state, props) => ({
    conditionValue: props.condition(state),
});

export default connect(mapStateToProps)(ConditionalRoute);
