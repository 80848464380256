import API from '../api';
import Actions from '../redux/actions';
import { DEVICE_ID } from '../constants';
import Firebase from '../helper/Firebase';
import { safe } from '../utils';
import { store } from '../Root';

let userInstance = null;
let tokenUnsubscribe = null;
const firebase = new Firebase();

const handletokenRefresh = (tokenRefresh, user)=> {
    window.debug.log('auth', 'Token refreshed.', user);
    userInstance = user;
    if (tokenRefresh) {
        tokenRefresh(user);
    }
};

const handleUnload = () => {
    if (tokenUnsubscribe) {
        tokenUnsubscribe();
        tokenUnsubscribe = null;
    }
};

const authInit = async tokenRefresh => {
    window.addEventListener('beforeunload', handleUnload);
    if (tokenUnsubscribe) {
        tokenUnsubscribe();
    }

    tokenUnsubscribe = handletokenRefresh(tokenRefresh, await firebase.getFirebaseUser());
};

const refreshUserToken = async (force = false) => {
    try {
        const idToken =  await firebase.refreshIdToken();
        Actions.updateIdToken(window.store.dispatch, idToken);
    } catch (error) {
        throw error;
    }
};

const signOut = async () => {
    const deviceId = localStorage.getItem(DEVICE_ID);
    if (deviceId) {
        await API.unregisterDevice(deviceId);
        localStorage.removeItem(deviceId);
    }
    return firebase.doSignOut();
};

const isLoggedIn = state => {
    const user = safe(state || store.getState(), 'account/userInfo');
    return user && user.uid && user.uid.length > 0 && user.email && user.email.length > 0;
};

export default {
    authInit,
    get userInstance() {
        return userInstance;
    },
    refreshUserToken,
    signOut,
    isLoggedIn,
};
