import Task from './Task';
import API from '../../api';

export default class DeleteHistoryTask extends Task {
    created_at;

    constructor(store, created_at, events) {
        super(store, events);
        this.created_at = created_at;
    }

    process = async _ => await API.transfer.link.remove(this.created_at);
}
