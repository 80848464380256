import './bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import 'normalize.css';
import 'events';
import '@bit/estmob.frontier-components.fonts/style.scss';
// import './styles/index.scss';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();
