import Actions from '../redux/actions';
import Auth from './auth';
import { Errors } from '../constants';
import Path from 'path';
import axios from 'axios';
import { clearSessionStorage } from '../utils';
import { store } from '../Root';

const getUserInfo = () => store.getState().account.userInfo;
const getId = () => store.getState().account.userInfo.uid;
const getToken = () => store.getState().account.userInfo.idToken; //+ wasRestored() ? '1' : ''

const encode = path => {
    if (path === '/') {
        return path;
    }
    return encodeURIComponent(path);
};

const map = new Map();

const preprocessPath = path => {
    if (path === '/' || path === '') {
        return '';
    }
    return Path.join(path, '/');
};

const getInstance = (url, service, version, param = null) => {
    if (map.has(service)) {
        return map[service];
    }

    const baseUrl = `${url}/${service}/v${version}/`;
    const instance = axios.create({
        baseURL: baseUrl,
        timeout: 60000,
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
        ...param,
    });
    map[service] = instance;

    return instance;
};

const clearInstance = () => {
    map.clear();
};

const implement = (url, service, version, block, param, maxRetry) => handleError(() => block(getInstance(url, service, version, param)), 0, maxRetry);

const isManaged = result => {
    return result && result._managed === true;
};

const isSucceeded = (result, block = null) => {
    const ret = result && result._managed && result.result;
    ret && block && block(result.data);
    return ret;
};

const ifSucceeded = (result, block, elseValue = null) => {
    if (result && result._managed && result.result && block) {
        return block(result.data, result.data.data);
    }
    return elseValue;
};

const isFailed = (error, block = null) => {
    const ret = error && (!error._managed || !error.result);
    ret && block && block(error.error);
    return ret;
};

const ifFailed = (result, block, defValue = null) => {
    if (result && block) {
        if (result._managed && result.error) {
            return block(result.error, result.error.data);
        }
    }
    return defValue;
};

const ifTrue = (value, block) => {
    if (value) {
        return block(value) || true;
    }
    return false;
};

const extractData = (result, block = null) => {
    let res = null;
    if (result) {
        if (result._managed && result.result && result.data && result.data.data) {
            res = result.data.data;
        } else if (result.data) {
            res = result.data;
        }
    }
    if (res && block) {
        return block(res);
    }
    return res;
};

const extractResponse = (error, block = null) => {
    if (error) {
        let res = error;
        if (error._managed) {
            res = error.error;
        }
        if (res && res.response) {
            if (block) {
                return block(res.response);
            }
            return res.response;
        }
    }
    return null;
};

const extractCode = (result, block = null) => {
    if (result) {
        if (result._managed && result.code) {
            if (block) {
                return block(result.code);
            }
            return result.code;
        }
    }
    return null;
};

const getSafeErrorCode = error => {
    if (isManaged(error)) {
        return error.code;
    } else {
        return Errors.UNKNOWN;
    }
};

const managedOk = (code = Errors.SUCCESS, data) => ({
    _managed: true,
    code,
    result: true,
    data,
});

const managedError = (code = Errors.UNKNOWN, error) => ({
    _managed: true,
    code,
    result: false,
    error,
});

const managedReject = (reject, error, ifNot = null) => {
    let res = null;
    if (error && error._managed) {
        res = error;
    } else {
        res = Object.assign(
            {
                _managed: true,
                result: false,
                code: Errors.UNKNOWN,
                error: error,
            },
            ifNot,
        );
    }
    reject && reject(res);
    return res;
};

const managedResolve = (resolve, result, ifNot = null) => {
    let res = null;
    if (result && result._managed) {
        res = result;
    } else {
        res = Object.assign(
            {
                _managed: true,
                result: true,
                code: Errors.SUCCESS,
                data: result,
            },
            ifNot,
        );
    }
    resolve && resolve(res);
    return res;
};

const handleError = (promise, retry = 0, maxRetry = 3) =>
    new Promise((resolve, reject) => {
        promise()
            .then(result => {
                ifTrue(extractData(result), data => {
                    if (data.usage_size) {
                        Actions.updateAccountSetting(
                            store.dispatch,
                            {
                                cloud: {
                                    used: data.usage_size,
                                },
                            },
                            true,
                        );
                    }
                });
                managedResolve(resolve, result);
            })
            .catch(error => {
                window.debug.log('Default error handler', error);
                ++retry;
                if (retry > maxRetry) {
                    // if (error && error.response && error.response.status === 401) {
                    //     redirectToLogin();
                    // }
                    return managedReject(reject, error, { code: Errors.MAX_RETRY });
                }
                if (isManaged(error) && error.code !== Errors.UNAUTHORIZED) {
                    const response = extractResponse(error);
                    if (response && response.status && response.status !== 401) {
                        return managedReject(reject, error);
                    }
                }
                if (error && error.response) {
                    switch (error.response.status) {
                        case 400:
                            switch (error.response.data ? error.response.data.error : null) {
                                case 'SENDY_ERR_FILE_LONG_KEY':
                                    managedReject(reject, error, { code: Errors.TOO_LONG_NAME });
                                    break;
                                case 'out_of_storage':
                                    managedReject(reject, error, { code: Errors.STORAGE_LIMIT_EXCEEDED });
                                    break;
                                default:
                                    managedReject(reject, error, { code: Errors.BAD_REQUEST });
                                    break;
                            }
                            break;
                        case 401:
                            window.debug.log('Default error handler', 'Unautorized', getToken());
                            clearInstance();
                            let refreshCounter = 0;
                            const refreshTask = () => {
                                window.debug.log(`Retrying (retry: ${retry}, waitingCounter: ${refreshCounter})`);
                                if (Auth.userInstance) {
                                    window.debug.log('Default error handler', 'Refreshing token');
                                    Auth.refreshUserToken(true)
                                        .then(result => {
                                            window.debug.log('Default error handler', 'Refreshing token succeeded', result);
                                            setTimeout(() => {
                                                handleError(promise, retry, maxRetry)
                                                    .then(result => {
                                                        resolve(result);
                                                    })
                                                    .catch(error => {
                                                        reject(error);
                                                    });
                                            }, 500);
                                        })
                                        .catch(error => {
                                            window.debug.log('Default error handler', 'Refreshing token error', error);
                                            managedReject(reject, error, { code: Errors.UNAUTHORIZED });
                                        });
                                } else {
                                    ++refreshCounter;
                                    if (refreshCounter > 10) {
                                        window.debug.log('Default error handler', 'User instance is null', error);
                                        clearSessionStorage();
                                        //                                        redirectToLogin();
                                        managedReject(reject, { code: Errors.UNAUTHORIZED });
                                    } else {
                                        setTimeout(refreshTask, 500);
                                    }
                                }
                            };
                            setTimeout(refreshTask, 0);
                            break;
                        case 403:
                            managedReject(reject, error, { code: Errors.FORBIDDEN });
                            break;
                        case 404:
                            managedReject(reject, error, { code: Errors.NOT_FOUND });
                            break;
                        case 500:
                            managedReject(reject, error, { code: Errors.INTERNAL_SERVER });
                            break;
                        case 503:
                            managedReject(reject, error, { code: Errors.SERVICE_UNAVAILABLE });
                            break;
                        default:
                            managedReject(reject, error);
                            break;
                    }
                } else {
                    managedReject(reject, error);
                }
            });
    });

export default {
    preprocessFolderPath: preprocessPath,
    getUserInfo,
    getId,
    getToken,
    getInstance,
    implement,
    encode,
    handleError,
    ifTrue,
    isManaged,
    isFailed,
    ifFailed,
    extractData,
    extractResponse,
    extractCode,
    getSafeErrorCode,
    isSucceeded,
    ifSucceeded,
    managedOk,
    managedError,
    managedReject,
    managedResolve,
};

// const promise = new Promise((resolve, reject) => {
//     resolve(1)
// })
// .then(result => {
//     window.debug.log(result)
//     return new Promise(resolve => resolve(2))
// })
// .then(result => {
//     return new Promise(resolve => {
//         throw { error: "Error"}
//     })
// })
// .catch(error => {
//     return new Promise(resolve => {setTimeout(() => resolve(10), 10000)})
// })
// .then(result => window.debug.log(result))
// .then(result => window.debug.log(result))
