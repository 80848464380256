export default {
    account: {
        userInfo: {},
        settings: {
            account: {},
            cloud: {
                settings: {},
            },
        },
    },
    status: {},
};
