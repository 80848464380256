import Common from '../common';
import { ServiceInfo } from '../../constants';

const SERVICE = 'transfer';
const VERSION = 1;

const implement = block => Common.implement(ServiceInfo.otherApiUrl, SERVICE, VERSION, block);

const getSetting = reload => implement(instance => instance.get('/setting', { params: { reload } }));

export default {
    getSetting,
};
