import NotifyType from './notifyType';

const Operations = {
    DELETE: {
        id: 'DELETE',
        notification: {
            notify: NotifyType.FAIL,
        },
    },
    NEW: {
        id: 'NEW',
        notification: {
            notify: NotifyType.FAIL,
        },
    },
};

export default Operations;
