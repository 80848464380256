import API from '../api';
import throttle from 'lodash.throttle';
import PromiseQueue from 'promise-queue';
import Actions from '../redux/actions';
import { Errors } from '../constants';

class UploadManager {
    uploadQueue = new PromiseQueue(1, Infinity);
    store = null;
    canceled = false;
    isRunning = false;
    lastPromise = null;

    constructor(store) {
        this.store = store;
        window.addEventListener('beforeunload', e => {
            if (this.uploadQueue.getPendingLength() + this.uploadQueue.getQueueLength() > 0) {
                const message = 'Changes you made may not be saved.';
                e.returnValue = message;
                return message;
            }
        });
    }

    updateProgress = throttle(
        (tp, cp, tc, cc) => {
            //        window.debug.log(tp, cp, tc, cc)
            Actions.setStatusTransferProgress(this.store.dispatch, tp, cp, tc, cc);
        },
        100,
        { leading: false, trailing: true },
    );

    handleProgress = (tp, cp, tc, cc) => {
        this.updateProgress(100, (cp * 100) / tp, tc, cc);
        return this.canceled;
    };

    pushUpload = (files, currentPath, hostId, keyPath) => {
        this.canceled = false;
        return new Promise((resolve, reject) => {
            this.uploadQueue.add(() => {
                this.updateProgress(0, 0, files.length, 0);
                if (this.canceled) {
                    API.managedReject(reject, null, { code: Errors.USER_ABORTED });
                    return;
                }
                return API.upload(files, currentPath, hostId, keyPath, this.handleProgress)
                    .then(result => {
                        this.updateProgress(100, 100, files.length, files.length);
                        resolve(result);
                        // setTimeout(() => resolve(result), 5000)
                    })
                    .catch(error => {
                        reject(error);
                        // setTimeout(() => reject(error), 5000)
                    });
            });
        });
    };

    cancelUpload = () => {
        this.canceled = true;
    };
}

export default UploadManager;
