import React from 'react';
import { clearSessionStorage } from './utils';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
        clearSessionStorage();
    }

    render() {
        const { children, ...others } = this.props;
        const childrenWithProps = React.Children.map(children, child => React.cloneElement(child, others));

        return childrenWithProps;
    }
}

export default ErrorBoundary;
