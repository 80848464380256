import Actions from '../actions';

export default (state = {}, action) => {
    switch (action.type) {
        case Actions.UPDATE_PROFILE:
            return Object.assign({}, state, {
                ...state.profileCache,
                [action.id]: action.profile,
            });
        case Actions.DELETE_PROFILE:
            const newCache = Object.assign({}, state.profileCache);
            delete newCache[action.id];
            return Object.assign({}, state, newCache);
        case Actions.CLEAR_PROFILE:
            return Object.assign({}, state, {});
        default:
            return state;
    }
};
