import { Errors, FileTypes } from '../constants';
import { Routes, ServiceInfo, SupportLanguages } from '../constants';

import API from '../api';
import Actions from '../redux/actions';
import Path from 'path';
import { clearSessionStorage } from './persistent-storage';
import filenameReservedRegex from 'filename-reserved-regex';
import { store } from '../Root';
import {
    RID_AUTH_CODE
  } from '../constants';

export const arrow = {
    arrow: {
        position: 'absolute',
        fontSize: 6,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
};

export const arrowGenerator = color => ({
    '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.95em',
        width: '3em',
        height: '1em',
        '&::before': {
            borderWidth: '0 1em 1em 1em',
            borderColor: `transparent transparent ${color} transparent`,
        },
    },
    '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.95em',
        width: '3em',
        height: '1em',
        '&::before': {
            borderWidth: '1em 1em 0 1em',
            borderColor: `${color} transparent transparent transparent`,
        },
    },
    '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.95em',
        height: '3em',
        width: '1em',
        '&::before': {
            borderWidth: '1em 1em 1em 0',
            borderColor: `transparent ${color} transparent transparent`,
        },
    },
    '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.95em',
        height: '3em',
        width: '1em',
        '&::before': {
            borderWidth: '1em 0 1em 1em',
            borderColor: `transparent transparent transparent ${color}`,
        },
    },
});

export const ensureRangeCO = (value, min, max) => {
    if (value < min) {
        return min;
    }
    if (value >= max) {
        return max - 1;
    }

    return value;
};

export const ensureRangeCC = (value, min, max) => {
    if (value < min) {
        return min;
    }
    if (value > max) {
        return max;
    }

    return value;
};

export const overflowRangeCO = (value, min, max) => {
    if (value < min) {
        return max - 1;
    }
    if (value >= max) {
        return min;
    }

    return value;
};

export const overflowRangeCC = (value, min, max) => {
    if (value < min) {
        return max;
    }
    if (value > max) {
        return min;
    }

    return value;
};

export const inRangeCO = (value, min, max) => {
    return value !== null && value !== undefined && value >= min && value < max;
};

export const inRangeCC = (value, min, max) => {
    return value !== null && value !== undefined && value >= min && value <= max;
};

export const redirectToLogin = history => {
    history && history.push(Routes.SIGN_IN);
};

export const redirectToError = history => {
    history.push(Routes.ERROR);
};

export const openTransfer = () => {
    const url = ServiceInfo.transferUrl;
    window.open(url, '_blank');
};

export const redirectToAccount = () => {
    const url = ServiceInfo.accountSettingUrl;
    window.location.href = url;
};

export const redirectToTransfer = data => {
    if (data) {
        const url = ServiceInfo.transferFilesUrl;
        const serialized = JSON.stringify(data);
        sessionStorage.setItem('__copy_to_transfer', serialized);
        window.location.href = url;
    } else {
        const url = ServiceInfo.transferUrl;
        window.location.href = url;
    }
};

export const ifDefined = (key, value) => {
    return value ? { [key]: value } : null;
};

export const isNullOrEmptyString = value => !value || value.length === 0;

export const isValidFileName = name => {
    return !filenameReservedRegex().test(name);
};

export const clearAuthInfo = () => {
    Actions.setAuthInfo(window.store.dispatch, {});
    localStorage.clear();
    clearSessionStorage();
}

export const signOut = history => {
    if (API.signOut()) {
        clearAuthInfo();
    }
};

export const updateCurrentLanguage = async (i18n, language, force) => {
    try {
        let lang = language;
        if (typeof language === 'string') {
            lang = SupportLanguages.match(language);
        }
        if (lang && store) {
            if (lang.i18nLanguage !== i18n.language || force === true) {
                await i18n.changeLanguage(lang.i18nLanguage);
            }
            Actions.updateAccountSettings(store.dispatch, { language: lang.langCode }, true);
            API.cloud.setAccountSettings({ language: lang.langCode });
            localStorage.setItem('__intl', lang.langCode);
        }
    } catch (error) {
        window.debug.error(`Changing language to ${language} failed.`, error);
    }
};

export function isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

export const managedLog = (...obj) => {
    window.debug.log(...obj);
};

export const getDirname = (name, fallback = null) => {
    const res = Path.dirname(name);
    if (res === '.') {
        return fallback;
    }
    return res;
};

const fallbackCopyTextToClipboard = text => {
    if (!text) {
        return false;
    }
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'absolute';
    textArea.style.left = '-1000px';
    textArea.style.top = '-1000px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        window.debug.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        window.debug.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
    return true;
};

export const copyTextToClipboard = async text => {
    return fallbackCopyTextToClipboard(text);
};

export const noneOf = (array, block) => {
    return !array.some(item => block(item));
};

export const isAll = (block, ...items) => {
    return !items.some(item => !block(item));
};

export const isNone = (block, ...items) => {
    return !items.some(item => block(item));
};

export const isDefined = (...items) => {
    return isAll(item => item, ...items);
};

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
export const shallowEqual = (objA, objB, ...excepts) => {
    if (objA === objB) {
        return true;
    }

    if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
        return false;
    }

    const exset = new Set(excepts);
    var keysA = Object.keys(objA).filter(item => !exset.has(item));
    var keysB = Object.keys(objB).filter(item => !exset.has(item));

    if (keysA.length !== keysB.length) {
        return false;
    }

    // Test for A's keys different from B.
    var bHasOwnProperty = hasOwnProperty.bind(objB);
    for (var i = 0; i < keysA.length; i++) {
        if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
            return false;
        }
    }

    return true;
};

export const shallowCompare = (instance, nextProps, nextState, ...excepts) => {
    return !shallowEqual(instance.props, nextProps, ...excepts) || !shallowEqual(instance.state, nextState, ...excepts);
};

export const getPortalContainer = () => document.getElementById('portal');

export const postRequest = (root, url, data, opt) => {
    const formElement = document.createElement('form');
    formElement.method = 'POST';
    formElement.action = url;
    if (opt) {
        Object.entries(opt).forEach(item => {
            formElement[item[0]] = item[1];
        });
    }

    Object.entries(data).forEach(item => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = item[0];
        input.value = item[1];
        formElement.appendChild(input);
    });

    root.appendChild(formElement);
    formElement.submit();
    root.removeChild(formElement);
};

export const getAllFilesFromSelection = async (uid, currentPath, selection) => {
    const folders = Object.values(selection)
        .filter(item => item.type === FileTypes.FOLDER)
        .map(item => Path.basename(item.path) + '/');
    const data = Object.values(selection)
        .filter(item => item.type !== FileTypes.FOLDER)
        .map(item => ({
            path: item.path,
            size: item.size,
        }));
    if (folders.length > 0) {
        const result = await API.getFilesToDownload(uid, currentPath, folders, { filter: 'file' });
        const resultData = API.extractData(result);
        if (resultData) {
            return {
                hostId: uid,
                files: data.concat(
                    resultData.file.map(item => ({
                        path: item.Path,
                        size: item.Size,
                    })),
                ),
            };
        }
    }
    return {
        hostId: uid,
        files: data,
    };
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function deepAssign(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                deepAssign(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return deepAssign(target, ...sources);
}

export const intersectRect = (r1, r2) => !(r2.left > r1.right || r2.right < r1.left || r2.top > r1.bottom || r2.bottom < r1.top);

export const ifYes = (condition, block) => (condition ? block() : undefined);

export const addZero = (n = 0) => {
    if (n < 0) n = 0;
    return n < 10 ? '0' + n : n;
};

export const wait = m => new Promise(r => setTimeout(r, m));

export const loopTimeout = (interval, block, timeout) =>
    new Promise(async (resolve, reject) => {
        let utime = timeout ? +new Date() + timeout : 0;
        let result = undefined;
        while (true) {
            await wait(interval);
            try {
                result = await block();
                if (timeout && +new Date() > utime) {
                    return reject(API.managedError(Errors.TIMEOUT));
                }
            } catch (error) {
                reject(error);
            }
            if (result) {
                return resolve(result);
            }
            utime = timeout ? +new Date() + timeout : 0;
        }
    });

export const postMessageToParent = msg => {
    if (window.opener) {
        const target = decodeURIComponent((window.query && window.query.target) || window.location.protocol + '//' + window.location.hostname);
        //console.log(target);
        window.opener.postMessage(msg, target);
    } else {
        window.parent.postMessage(msg, '*');
    }
};

export const setMSCookie = async (value) => {
    var myDate = new Date();
    myDate.setMinutes(myDate.getMinutes() + 3);
    document.cookie =
        RID_AUTH_CODE + '=' + value + ';expires=' + myDate + ';domain=.rakuten-drive.com;path=/;secure;samesite=None';
};

export const deleteMSCookie = () => {
    var myDate = new Date();
    myDate.setMinutes(myDate.getMinutes() + 3);
    document.cookie = RID_AUTH_CODE + "= " + isLoggout + ";expires=" + myDate + ";domain=.rakuten-drive.com;path=/;secure;samesite=None";
}

export const getCookieValue = (name) => {
    const regex = new RegExp(`(^| )${name}=([^;]+)`);
    const match = document.cookie.match(regex);
    if (match) {
        return match[2];
    } else {
        return ''
    }
};