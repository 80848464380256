import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router'; // react-router와 redux 동기화
import ReduxThunk from 'redux-thunk'; // 비동기 액션을 위한 미들웨어
import createRootReducer from './reducers';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { IS_DEV } from '../environment';

// 리덕스 개발자 도구
// https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
//const REDUX_DEVTOOLS = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : ;

const browserHistory = createBrowserHistory(); //{ basename: process.env.REACT_APP_URL_PREFIX });
// const history = createBrowserHistory({ basename: process.env.REACT_APP_URL_PREFIX });

let middleWares = [routerMiddleware(browserHistory), ReduxThunk];

if (IS_DEV) {
    const logger = createLogger({
        collapsed: true,
        level: 'info',
    });
    middleWares.push(logger);
}

const configureStore = preloadedState => createStore(createRootReducer(browserHistory), preloadedState, composeWithDevTools(applyMiddleware(...middleWares)));

export default configureStore;
export { browserHistory };
