export const ServiceInfo = {
    host:
        process.env.NODE_ENV === 'production' && window.location.hostname.startsWith('app-staging')
            ? 'https://app-staging.sendycloud.com'
            : process.env.REACT_APP_HOST,
    apiHost:
        process.env.NODE_ENV === 'production' && window.location.hostname.startsWith('app-staging')
            ? 'https://forest-staging.sendy.jp'
            : process.env.REACT_APP_API_HOST,
    messageHost:
        process.env.NODE_ENV === 'production' && window.location.hostname.startsWith('app-staging')
            ? 'wss://message-test.sendycloud.com'
            : process.env.REACT_APP_MESSAGE_API_HOST,       
};

ServiceInfo.accountUrl = `${ServiceInfo.host}/account`;
ServiceInfo.accountForgotUrl = `${ServiceInfo.accountUrl}/password/reset`;
ServiceInfo.accountCreateUrl = `${ServiceInfo.accountUrl}/signup`;
ServiceInfo.transferUrl = `${ServiceInfo.host}/transfer`;
ServiceInfo.transferFilesUrl = `${ServiceInfo.host}/transfer/copy-from-cloud`;
ServiceInfo.transferSentUrl = `${ServiceInfo.host}/transfer/sent`;
ServiceInfo.cloudHost = ServiceInfo.host;
ServiceInfo.cloudUrl = `${ServiceInfo.cloudHost}/cloud`;
ServiceInfo.cloudSettingsUrl = `${ServiceInfo.cloudHost}/cloud/settings/1`;
ServiceInfo.accountSettingUrl = `${ServiceInfo.accountUrl}/personal-info`;
ServiceInfo.accountInvitationUrl = `${ServiceInfo.accountUrl}/referral?service=cloud`;
ServiceInfo.accountVerifyUrl = `${ServiceInfo.accountUrl}/verify`;
ServiceInfo.cloudApiUrl = `${ServiceInfo.apiHost}/cloud/service`;
ServiceInfo.otherApiUrl = ServiceInfo.apiHost;
ServiceInfo.transferApiUrl = `${ServiceInfo.apiHost}`;
ServiceInfo.messageApiUrl = ServiceInfo.messageHost;
ServiceInfo.cloudSelectUrl = `${ServiceInfo.cloudUrl}/explore?theme=teal&mode=extension&target=${encodeURIComponent(
    window.location.protocol + '//' + window.location.hostname,
)}`;
ServiceInfo.billingUrl = `${ServiceInfo.accountUrl}/plan-billing`;
ServiceInfo.helpUrl = 'https://support.rakuten-drive.com/';
ServiceInfo.privacyUrl = `${process.env.REACT_APP_HOME_RAKUTEN_DRIVE}/privacy`;
ServiceInfo.termsUrl = `${process.env.REACT_APP_HOME_RAKUTEN_DRIVE}/terms`;
